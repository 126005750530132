export const faq3Data = [
  {
    title: "How do I identify and evaluate the best potential investments for me?",
    content: `Identifying and evaluating potential web3 investments involves staying up-to-date on trends and opportunities, identifying and evaluating investment opportunities, and performing due diligence. We provide the latest updates on web3 trends and opportunities, and guidance on how to identify and evaluate potential investments.`,
    collapse: true,
  },
  {
    title: "How can I best add value to portfolio companies and help them grow?",
    content: `Investors can add value to web3 portfolio companies and help them grow by providing resources and support, being a strategic advisor, and helping the company navigate challenges. Caduceus provides guidance on how to best add value to web3 portfolio companies.`,
    collapse: true,
  },
  {
    title: "How do I create a diversified web3 portfolio that balances risk and reward?",
    content: `Creating a diversified web3 portfolio is important for any VC in Web3. It involves evaluating the risk profile of each potential investment, balancing early-stage and later-stage investments, and considering your firm's investment focus and strategy. Caduceus can help guide you in creating a more diversified web3 portfolio.`,
    collapse: true,
  },
  {
    title: "How do I stay up-to-date on market trends and opportunities in web3?​",
    content: `Its easy to stay up-to-date on market trends and opportunities when you partner with Caduceus and sign up for our newsletter. As experts in the web3 ecosystem, we have a deep understanding of the trends and opportunities shaping the market. By partnering with us, you can tap into this expertise and gain valuable insights into the web3 space.`,
    collapse: true,
  },
  {
    title:
      "How do I negotiate terms and close deals in the web3 ecosystem?",
    content: `Successfully negotiating and closing web3 deals involves understanding the unique characteristics of the web3 ecosystem and how they impact dealmaking. This might include being familiar with the trends and best practices in web3 financing, as well as understanding the specific needs and challenges faced by web3 companies. Caduceus provides expert guidance on closing deals in the web3 ecosystem.`,
    collapse: true,
  },
  {
    title: "How do I create and execute a strong exit strategy?",
    content: `Developing and executing a strong exit strategy is an important part of any venture capital firm's investment strategy. This can be particularly true in the web3 ecosystem. A well-planned exit strategy can help investors realize returns on their web3 investments and provide a roadmap for exiting portfolio companies when the time is right. Caduceus provides our clients with expert guidance on creating and executing a strong exit strategy.`,
    collapse: true,
  },
];
