import React, { useState, useEffect } from "react";
import { Icon } from "ui/atoms";
import Icons from "assets/icons";
import axios from "axios";
import { SERVER_URL } from "config/api";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface ICheckBoxProps {
  _id: string;
  unCheckedIcon?: string;
  checkedIcon?: string;
  defaultCheckState?: boolean;
  stateChangable?: boolean;
  starCountGetter: Function;
  setter: Function;
}

const Checker: React.FC<ICheckBoxProps> = ({
  _id,
  unCheckedIcon = Icons.star,
  checkedIcon = Icons.starBlack,
  defaultCheckState = false,
  stateChangable = true,
  starCountGetter = () => {},
  setter = () => {},
}) => {
  const [checked, setChecked] = useState<boolean>(defaultCheckState);
  useEffect(() => {
    setChecked(defaultCheckState);
  }, [defaultCheckState]);
  return (
    <>
      <ToastContainer />
      <Icon
        icon={checked ? checkedIcon : unCheckedIcon}
        onClick={() => {
          if (stateChangable) {
            if (checked === false && starCountGetter() >= 4) {
              toast.warn("Maximum Top Picks Limit Reached");
              return;
            } else {
              setChecked(!checked);
              setter(_id, !checked);
              axios
                .get(SERVER_URL + "/make-star", {
                  params: {
                    _id: _id,
                    is_star: !checked,
                  },
                })
                .then((res) => {
                  console.log("##############", res.data);
                })
                .catch((err) => console.log(err));
            }
          }
        }}
      />
    </>
  );
};

export default Checker;
