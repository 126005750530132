import React from "react";
import { Container, Box, ContentWrapper, Text } from "ui/atoms";
import { Page, Table } from "ui/molecules";
import { COLORS } from "config/colors";
import { connect } from "react-redux";
import { changeTheme } from "redux/actions/theme";
import useWindowDimensions from "hooks/useWindowDimensions";
import { WINDOW_SIZES } from "config/dimensions";
import { changeAuditModalOpened } from "redux/actions/requestAuditModal";
// import contractData from "assets/json/contracts.json";

const Requests = (props: any) => {
  const { deviceWidth } = useWindowDimensions();
  return (
    <Page>
      <ContentWrapper backgroundColor={COLORS.BLUE_THEME_DARK_BLUE}>
        <Container>
          <Box padding={30}></Box>
          <Box
            paddingHorizontal={deviceWidth > WINDOW_SIZES.SIZE_768 ? 0 : 0}
            paddingVertical={50}
          >
            <Box padding={50}>
              <Text center type="title" fontWeight={600}>
                Leaderboard
              </Text>
            </Box>
            <Table />
          </Box>
        </Container>
      </ContentWrapper>
    </Page>
  );
};

const mapStateToProps = (state: any) => {
  return {
    theme: state.ThemeReducer.theme,
    requestAuditModal: state.RequestAuditModalReducer.auditModalOpened,
  };
};

export default connect(mapStateToProps, {
  changeTheme: changeTheme,
  changeAuditModalOpened: changeAuditModalOpened,
})(Requests);
