export const PADDING = {
  EXTRA_SMALL: 2,
  SMALL: 4,
  MEDIUM: 8,
  LARGE: 16,
  EXTRA_LARGE: 24,
  SUPER_LARGE: 48
};

export const GAP = {
  EXTRA_SMALL: 2,
  SMALL: 4,
  MEDIUM: 8,
  LARGE: 16,
  EXTRA_LARGE: 24,
  SUPER_LARGE: 48
};

export const FONT = {
  EXTRA_SMALL: 4,
  SMALL: 8,
  MEDIUM: 11,
  LARGE: 15,
  EXTRA_LARGE: 24,
  SUPER_LARGE: 48
};

export const BORDER_RADIUS = {
  SMALL: 4,
  MEDIUM: 8,
  LARGE: 12
};

export const WINDOW_SIZES = {
  SIZE_4000: 4000,
  SIZE_3000: 3000,
  SIZE_1920: 1920,
  SIZE_1600: 1600,
  SIZE_1280: 1280,
  SIZE_1024: 1024,
  SIZE_1000: 1000,
  SIZE_960: 960,
  SIZE_874: 874,
  SIZE_768: 768,
  SIZE_464: 464,
  SIZE_375: 375,
  SIZE_320: 320,
  SIZE_240: 240,
};

export const Z_INDEX_LEVELS = {
  LOWEST: 0,
  VERY_LOW: 1,
  LOW: 2,
  NORMAL: 3,
  MEDIUM: 4,
  HIGH: 5,
  VERY_HIGH: 6,
  SUPER: 7,
  MAXIMUM: 8
};

export const PAGINATOR_ARROW = {
  left: -1,
  right: -2,
}