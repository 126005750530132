import React from "react";
import { Flex, Text, Container, Box, ContentWrapper, Icon, Image } from "ui/atoms";
import {
  Page,
  TextBoxGreen,
  TextBoxPurple,
  TextBoxTeal,
  Button,
} from "ui/molecules";
import { COLORS } from "config/colors";
import Icons from "assets/icons";
import { connect } from "react-redux";
import { changeTheme } from "redux/actions/theme";
import useWindowDimensions from "hooks/useWindowDimensions";
import { WINDOW_SIZES } from "config/dimensions";
import { changeAuditModalOpened } from "redux/actions/requestAuditModal";
import Marquee from "react-fast-marquee";
import { Popup } from 'react-typeform-embed';
// import contractData from "assets/json/contracts.json";

const Audit = (props: any) => {
  const { deviceWidth } = useWindowDimensions();
  return (
    <Page>
      <ContentWrapper
        //backgroundColor={COLORS.DARK_THEME_GRAY_BACKGROUND}
        backgroundImage={
          "linear-gradient(to bottom left, #000000, #000000)"
        }
      >
        <Box padding={42} />
        <Container>
          <Box padding={0}></Box>
          <Box paddingHorizontal={20} paddingVertical={60}>
            <Flex flexDirection="column" alignItems="center" gap={30}>
          <Box maxWidth={1000}>
                  <Image
                    image={Icons.caduceusLogoGif}
                    width={deviceWidth > WINDOW_SIZES.SIZE_768 ? "200" : "100"}
                  />
                </Box>
              <Text center type={"title"} fontWeight={600} fontFamily={"RextonBold"}>
              Web3 Fundraising Partners
              </Text>
              <Flex justifyContent="center" alignItems="center">
                <Text
                  center
                  type="smalltitle"
                  category="span"
                  color={COLORS.BLACK_THEME_GREEN}
                  fontWeight={600}
                >
                  Unlock Your&nbsp;
                  <Text
                    center
                    type="smalltitle"
                    fontFamily="GilroyBold"
                    category="span"
                    color={COLORS.BLACK_THEME_GREEN}
                  >
                    Fundraising&nbsp;
                  </Text>
                  Potential.
                </Text>
              </Flex>
              <Box padding={20}>
              <Button
                    backgroundColor={COLORS.BLACK_THEME_DEEP_GREEN}
                    backgroundHoverColor={COLORS.BLACK_THEME_DIM_GREEN}
                    shadow={false}
                    onClick={() => {window.location.replace("https://calendly.com/caduceuscapital/preconsultation");}}
                  >
                    FREE PRECONSULTATION
                  </Button>
              </Box>
              <Box padding={20} />
            </Flex>
          </Box>
        </Container>
      </ContentWrapper>
      <ContentWrapper backgroundColor={COLORS.BLACK_THEME_BLACK}>
        <Container>
          <Box paddingHorizontal={0} paddingVertical={30}>
            <Flex flexDirection="column" alignItems="center" gap={20}>
              <Text center type="paragraph" color={COLORS.BLACK_THEME_WHITE}>
                OUR PARTNERS
              </Text>
              <Box padding={5}></Box>
              <Box
                width="108%"
                height="100%"
                borderRadius={0}
                overflowX="hidden"
                overflowY="hidden"
              >
                <Marquee gradient={false} speed={50}>
                  {/* <Flex width="100%" gap={80}> */}
                  {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((item, index) => {
                    return (
                      <Box key={index}>
                        <Flex gap={50}>
                          <Icon icon={Icons.ethereum} size="SUPER_LARGE" />
                          <Icon icon={Icons.bnb} size="SUPER_LARGE" />
                          <Icon icon={Icons.avalanche} size="SUPER_LARGE" />
                          <Icon icon={Icons.polygon} size="SUPER_LARGE" />
                          <Icon icon={Icons.tron} size="SUPER_LARGE" />
                          <Box />
                        </Flex>
                      </Box>
                    );
                  })}
                  {/* </Flex> */}
                </Marquee>
              </Box>
            </Flex>
          </Box>
        </Container>
      </ContentWrapper>
      <ContentWrapper backgroundColor={COLORS.BLACK_THEME_BLACK}>
        <Container>
          <Box padding={60}></Box>
          <Box
            paddingHorizontal={deviceWidth > WINDOW_SIZES.SIZE_960 ? 100 : 0}
            paddingVertical={50}
          >
            <Flex flexDirection="column" alignItems="center" gap={20}>
              <Box maxWidth="100%" overflowX="hidden">
                <Text center noWrap category="p">
              <Text center type="title" fontWeight={200} category="span">
              We help Web3 teams that are actively&nbsp;
              </Text>
              <Text
                    center
                    type="title"
                    //fontFamily="GilroyBold"
                    category="span"
                    color={COLORS.BLACK_THEME_GREEN}
                  >
                    fundraising&nbsp;
                  </Text>
                    <Text
                    center
                    type="title"
                    //fontFamily="GilroyBold"
                    category="span"
                    color={COLORS.BLACK_THEME_WHITE}
                  >
                   or&nbsp;
                  </Text>
                  <br></br>
                   <Text
                    center
                    type="title"
                    //fontFamily="GilroyBold"
                    category="span"
                    color={COLORS.BLACK_THEME_GREEN}
                  >
                    deploying capital&nbsp;
                  </Text>
                    <Text
                    center
                    type="title"
                    //fontFamily="GilroyBold"
                    category="span"
                    color={COLORS.BLACK_THEME_WHITE}
                  >
                   by providing&nbsp;
                  </Text>
                   <Text
                    center
                    type="title"
                    //fontFamily="GilroyBold"
                    category="span"
                    color={COLORS.BLACK_THEME_GREEN}
                  >
                    guidance
                  </Text>
                    <Text
                    center
                    type="title"
                    //fontFamily="GilroyBold"
                    category="span"
                    color={COLORS.BLACK_THEME_WHITE}
                  >
                   , setting&nbsp;
                  </Text>
                   <Text
                    center
                    type="title"
                    //fontFamily="GilroyBold"
                    category="span"
                    color={COLORS.BLACK_THEME_GREEN}
                  >
                   appointments
                  </Text>
                    <Text
                    center
                    type="title"
                    //fontFamily="GilroyBold"
                    category="span"
                    color={COLORS.BLACK_THEME_WHITE}
                  >
                   , and facilitating&nbsp;
                  </Text>
                   <Text
                    center
                    type="title"
                    //fontFamily="GilroyBold"
                    category="span"
                    color={COLORS.BLACK_THEME_GREEN}
                  >
                   dealflow
                  </Text>
                    <Text
                    center
                    type="title"
                    //fontFamily="GilroyBold"
                    category="span"
                    color={COLORS.BLACK_THEME_WHITE}
                  >
                   , allowing teams to focus on&nbsp;
                  </Text>
                   <Text
                    center
                    type="title"
                    //fontFamily="GilroyBold"
                    category="span"
                    color={COLORS.BLACK_THEME_GREEN}
                  >
                  growth.
                  </Text>
                  </Text>
                  </Box>
              <Box padding={15}></Box>
              <Flex
                gap={30}
                flexDirection={
                  deviceWidth > WINDOW_SIZES.SIZE_1280 ? "row" : "column"
                }
              >
                <a
                  href="https://www.caduceusconsultants.com/founders-raising-funds"
                  style={{ textDecoration: "none" }}
                >
                  <TextBoxGreen
                    title="Founders Raising Funds"
                    titleColor={COLORS.BLACK_THEME_WHITE}
                    content="We help you rapidly reach your fundraising goals by optimizing your offer and connecting you with the right interested investors for you."
                    icon
                    link="#"
                    hoverBorder
                    minWidth={deviceWidth > WINDOW_SIZES.SIZE_1280 ? 400 : 0}
                    minHeight={deviceWidth > WINDOW_SIZES.SIZE_1280 ? 290 : 0}
                  />
                </a>
                <a
                  href="https://www.caduceusconsultants.com/investors-raising-funds"
                  style={{ textDecoration: "none" }}
                >
                  <TextBoxGreen
                    title="Investors Raising Funds"
                    titleColor={COLORS.BLUE_THEME_WHITE}
                    content="Caduceus partners with your VC Firm to help you pitch and secure LPs for your fund so you can start deploying capital in the Web3 space."
                    icon
                    link="#"
                    hoverBorder
                    minWidth={deviceWidth > WINDOW_SIZES.SIZE_1280 ? 400 : 0}
                    minHeight={deviceWidth > WINDOW_SIZES.SIZE_1280 ? 290 : 0}
                  />
                </a>
                <a
                  href="https://www.caduceusconsultants.com/investors-deploying-capital"
                  style={{ textDecoration: "none" }}
                >
                  <TextBoxGreen
                    title="Investors Deploying Capital"
                    titleColor={COLORS.BLUE_THEME_WHITE}
                    content="Deploying capital in Web3? Caduceus provides your VC firm with high quality dealflow and guidance on how to structure your investments."
                    icon
                    link="#"
                    hoverBorder
                    minWidth={deviceWidth > WINDOW_SIZES.SIZE_1280 ? 400 : 0}
                    minHeight={deviceWidth > WINDOW_SIZES.SIZE_1280 ? 290 : 0}
                  />
                </a>
              </Flex>
            </Flex>
          </Box>
        </Container>
      </ContentWrapper>
      <ContentWrapper
        backgroundColor={COLORS.BLACK_THEME_BLACK}
        backgroundImage={COLORS.BLACK_THEME_BLACK}
      >
        <Container>
          <Box padding={60}></Box>
          <Box paddingHorizontal={20} paddingVertical={50}>
            <Flex flexDirection="column" alignItems="center" gap={20}>
              <Text center type="title" fontWeight={600}>
                Do You Want To Make Fundraising Easier For Your Team?
              </Text>
              <Box padding={20}>
              <Button
                    backgroundColor={COLORS.BLACK_THEME_DEEP_GREEN}
                    backgroundHoverColor={COLORS.BLACK_THEME_DIM_GREEN}
                    shadow={false}
                    onClick={() => {window.location.replace("https://calendly.com/caduceuscapital/preconsultation");}}
                  >
                    FREE PRECONSULTATION
                  </Button>
                <Box padding={60}></Box>
              </Box>
            </Flex>
          </Box>
        </Container>
      </ContentWrapper>
    </Page>
  );
};

const mapStateToProps = (state: any) => {
  return {
    theme: state.ThemeReducer.theme,
    requestAuditModal: state.RequestAuditModalReducer.auditModalOpened,
  };
};

export default connect(mapStateToProps, {
  changeTheme: changeTheme,
  changeAuditModalOpened: changeAuditModalOpened,
})(Audit);
