import React, { useState, useEffect } from "react";
import { Box, Flex, Icon, Text, Image } from "ui/atoms";
import { Button, AlarmNotifier } from "ui/molecules";
import Icons from "assets/icons";
import { COLORS } from "config/colors";
import { Z_INDEX_LEVELS, WINDOW_SIZES } from "config/dimensions";
import { connect } from "react-redux";
import { changeTheme } from "redux/actions/theme";
import { changeAuditModalOpened } from "redux/actions/requestAuditModal";
import useWindowDimensions from "hooks/useWindowDimensions";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { SERVER_URL } from "config/api";
import Spin from "ui/atoms/spin";

interface IMobileMenuProps {
  type?: "menu";
  hasDeals?: boolean;
  requestAuditModal: boolean;
  changeAuditModalOpenedRedux: Function;
}

interface ILinkProps {
  type: string;
  url: string;
}

interface IChainProps {
  title: string;
  checked: boolean;
}

interface ITableItemProps {
  _id: string;
  is_star: boolean;
  name: string;
  icon: string;
  trust_score: number;
  links: Array<ILinkProps>;
  contract: string;
  chain: Array<IChainProps>;
  category: string;
  audit_pdf: string;
  date: string;
}

interface ISubMenuItem {
  subTitle: string;
  link: string;
}

const MobileMenu: React.FC<IMobileMenuProps> = ({
  type = "menu",
}) => {
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const [subMenuCollapsed, setSubMenuCollapsed] = useState<boolean>(true);
  const [selectedNotification, setSelectedNotification] = useState<
    "recent" | "popular"
  >("popular");
  const { deviceWidth, deviceHeight } = useWindowDimensions();
  const [subMenuTitle, setSubMenuTitle] = useState<string>("");
  const [subMenuList, setSubMenuList] = useState<Array<ISubMenuItem>>([]);
  const navigate = useNavigate();

  const [tableData, setTableData] = useState<Array<ITableItemProps>>([]);

  const [recentDescription, setRecentDescription] = useState<string>("");
  const [topPicksDescription, setTopPicksDescription] = useState<string>("");

  const [notificationStatus, setNotificationStatus] = useState<
    "initial" | "loaded"
  >("initial");

  const [alarmNotified, setAlarmNotified] = useState<boolean>(false);
  const [display, setDisplay] = useState<"block" | "none">("block");

  setTimeout(() => {
    if (notificationStatus === "initial") {
      setNotificationStatus("loaded");
    }
  }, 20000); // 20s

  const getTableData = () => {
    return axios
      .get(SERVER_URL + "/table-data", {
        params: {},
      })
      .then(async (res) => {
        res.data.sort((a: any, b: any) => {
          if (a._id < b._id) return 1;
          return -1;
        });
        await setTableData(res.data);

        axios
          .get(SERVER_URL + "/notification-setting-data", {
            params: {},
          })
          .then(async (res) => {
            await res.data.sort((a: any, b: any) => {
              if (a._id < b._id) return 1;
              return -1;
            });
            await setRecentDescription(
              res.data
                .filter((item: any) => {
                  return item.type === "recent";
                })
                .slice(0, 1)[0].body_text
            );
            await setTopPicksDescription(
              res.data
                .filter((item: any) => {
                  return item.type === "top";
                })
                .slice(0, 1)[0].body_text
            );

            setNotificationStatus("loaded");
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <Box zIndex={Z_INDEX_LEVELS.MAXIMUM} cursor>
        {type === "menu" && <Box padding={2} />}
        <Box
          zIndex={
            type === "menu"
              ? Z_INDEX_LEVELS.MAXIMUM
              : Z_INDEX_LEVELS.MAXIMUM + 1
          }
          opacity={!(type === "menu" && !collapsed) ? 1 : 0}
        >
          <Icon
            icon={Icons.menu}
            size={type === "menu" && collapsed ? "UPPERMEDIUM" : "UPPERMEDIUM"}
            onClick={() => {
              setCollapsed(!collapsed);
            }}
          />
        </Box>
        {!collapsed && type === "menu" && (
          <>
            <Box position="absolute" width={200} height={50} right={-70}></Box>
            <Box
              position="absolute"
              right={deviceWidth > WINDOW_SIZES.SIZE_768 ? 7 : 0}
              top={45}
            >
            </Box>
            
              <Box overflowX="hidden" padding={15}>
                {notificationStatus === "initial" && <Spin />}
                {notificationStatus === "loaded" &&
                  selectedNotification === "recent" && (
                    <Box minHeight={200}>
                      {tableData.slice(0, 4).map((item) => {
                        return (
                          <Box>
                            <Flex width={"100%"} gap={10} alignItems="center">
                              <Box
                                borderRadius={5}
                                width={64}
                                minWidth={64}
                                maxWidth={64}
                                height={64}
                                minHeight={64}
                                maxHeight={64}
                                overflowX="hidden"
                                overflowY="hidden"
                                // boxShadow="0px 0px 10px #0a1325"
                              >
                              </Box>
                              <Box
                                borderColor={COLORS.BLUE_THEME_TRANSPARENT}
                                borderTopWidth={0.3}
                              >
                                <Text type="paragraph" fontWeight={600}>
                                  {item.name}
                                </Text>
                                <Text type="plain" color={"#C1C1C1"}>
                                  {recentDescription.replace(
                                    "{{Name}}",
                                    item.name
                                  )}
                                </Text>
                              </Box>
                            </Flex>
                          </Box>
                        );
                      })}
                    </Box>
                  )}
                {notificationStatus === "loaded" &&
                  selectedNotification === "popular" && (
                    <Box minHeight={200}>
                      {tableData
                        .filter((item) => {
                          return item.is_star === true;
                        })
                        .map((item, index) => {
                          return (
                            <Box>
                              <Flex width={"100%"} gap={10} alignItems="center">
                                <Box
                                  borderRadius={5}
                                  width={64}
                                  minWidth={64}
                                  maxWidth={64}
                                  height={64}
                                  minHeight={64}
                                  maxHeight={64}
                                  overflowX="hidden"
                                  overflowY="hidden"
                                  // boxShadow="0px 0px 10px #0a1325"
                                >
                                </Box>
                                <Box
                                  borderColor={COLORS.BLUE_THEME_TRANSPARENT}
                                  borderTopWidth={0.3}
                                >
                                  <Text type="paragraph" fontWeight={600}>
                                    {item.name}
                                  </Text>
                                  <Text type="plain" color={"#C1C1C1"}>
                                    {topPicksDescription.replace(
                                      "{{Name}}",
                                      item.name
                                    )}
                                  </Text>
                                </Box>
                              </Flex>
                            </Box>
                          );
                        })}
                    </Box>
                  )}
              </Box>
          </>
        )}
        <Box
          position="fixed"
          width={deviceWidth}
          height={!collapsed && type === "menu" ? deviceHeight + 40 : 1}
          transition="all 0.5s"
          backgroundColor={COLORS.BLACK_THEME_DIM_GREEN_TRANSPARENT}
          top={-40}
          left={0}
          padding={20}
        >
          <Box
            opacity={!collapsed && type === "menu" ? 1 : 0}
            transition={
              !collapsed && type === "menu" ? "all 1s ease-in-out" : "all 0.5s"
            }
            display={display}
          >
            <Box
              position="absolute"
              top={35}
              right={0}
              cursor
              padding={10}
              zIndex={Z_INDEX_LEVELS.MAXIMUM}
              onClick={() => {
                setCollapsed(true);
              }}
            >
              <Icon icon={Icons.close}></Icon>
            </Box>
            <Box padding={50} />
            <Flex flexDirection="column">
              <Box
                width="100%"
                padding={20}
                onClick={() => {
                  setSubMenuTitle("Services");
                  setSubMenuList([
                    {
                      subTitle: "Founders Raising Funds",
                      link: "founders-raising-funds#",
                    },
                    { subTitle: "Investors Raising Funds", link: "investors-raising-funds#" },
                    { subTitle: "Investors Deploying Capital", link: "investors-deploying-capital#" },
                  ]);
                  setSubMenuCollapsed(!subMenuCollapsed);
                }}
              >
                <Flex justifyContent="space-between">
                  <Text type="undersubtitle" fontWeight={600}>
                    Services
                  </Text>
                  <Icon icon={Icons.right}></Icon>
                </Flex>
              </Box>
              {/*
              <Box
                width="100%"
                padding={20}
                onClick={() => {
                  setCollapsed(true);
                  setSubMenuCollapsed(true);
                  navigate("/portfolio/");
                }}
              >
                <Flex justifyContent="space-between">
                  <Text type="undersubtitle" fontWeight={600}>
                    Portfolio
                  </Text>
                </Flex>
              </Box>
              */}
              <Box
                width="100%"
                padding={20}
                onClick={() => {
                  setCollapsed(true);
                  setSubMenuCollapsed(true);
                  navigate("/company/");
                }}
              >
                <Flex justifyContent="space-between">
                  <Text type="undersubtitle" fontWeight={600}>
                    Company
                  </Text>
                </Flex>
              </Box>
              <Box
                width="100%"
                padding={20}
                onClick={() => {
                  setSubMenuTitle("Socials");
                  setSubMenuList([
                    { subTitle: "Discord", link: "https://discordapp.com/users/1066783743425134622" },
                    {
                      subTitle: "Twitter",
                      link: "https://twitter.com/ConsultCaduceus",
                    },
                    {
                      subTitle: "LinkedIn",
                      link: "https://www.linkedin.com/company/consult-caduceus/",
                    },
                    {
                      subTitle: "Medium",
                      link: "https://medium.com/@consultcaduceus",
                    },
                  ]);
                  setSubMenuCollapsed(!subMenuCollapsed);
                }}
              >
                <Flex justifyContent="space-between">
                  <Text type="undersubtitle" fontWeight={600}>
                    Socials
                  </Text>
                  <Icon icon={Icons.right}></Icon>
                </Flex>
              </Box>
              <Box width="100%" padding={20} top={5}>
                <Flex justifyContent="center" width="100%">
                <Button
                    backgroundColor={COLORS.BLACK_THEME_DEEP_GREEN}
                    backgroundHoverColor={COLORS.BLACK_THEME_DIM_GREEN}
                    shadow={false}
                    onClick={() => {window.location.replace("https://calendly.com/caduceuscapital/preconsultation");}}
                  >
                    FREE PRECONSULTATION
                  </Button>
                </Flex>
              </Box>
            </Flex>
          </Box>
        </Box>
        {!subMenuCollapsed && type === "menu" && (
          <Box
            position="fixed"
            width={deviceWidth}
            height={deviceHeight}
            backgroundColor={COLORS.DARK_THEME_TRANSPARENT}
            top={0}
            right={0}
            padding={2}
          >
            <Flex width="100%" height="100%">
              {/* <Box
                width="30%"
                backgroundColor={COLORS.DARK_THEME_BLACK_TRANSPARENT}
                height="100%"
                onClick={() => {
                  setSubMenuCollapsed(true);
                }}
              ></Box> */}
              <Box width="100%" backgroundColor={COLORS.BLACK_THEME_DIM_GREEN_TRANSPARENT}>
                <Box padding={30} />
                <Flex flexDirection="column">
                  <Box
                    width="100%"
                    padding={30}
                    onClick={() => {
                      setSubMenuCollapsed(true);
                    }}
                  >
                    <Flex
                      justifyContent="center"
                      flexDirection="row"
                      alignItems="center"
                      width="100%"
                    >
                      <Flex flex={1}>
                        <Icon icon={Icons.left} />
                      </Flex>
                      <Flex flex={1} justifyContent="center">
                        <Text type="subtitle" fontWeight={600} center>
                          {subMenuTitle}
                        </Text>
                      </Flex>
                      <Flex flex={1}>
                        <Box>{}</Box>
                      </Flex>
                    </Flex>
                  </Box>
                  {subMenuList.map((subMenuItem, index) => {
                    return (
                      <Box
                        width="100%"
                        padding={20}
                        key={index}
                        onClick={() => {
                          setCollapsed(true);
                          setSubMenuCollapsed(true);

                          if (subMenuItem.link.startsWith("https")) {
                            window.location.replace(subMenuItem.link);
                          } else {
                            navigate(subMenuItem.link);
                          }
                        }}
                      >
                        <Flex
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Text type="undersubtitle" fontWeight={600}>
                            {subMenuItem.subTitle}
                          </Text>
                          <Icon icon={Icons.right}></Icon>
                        </Flex>
                      </Box>
                    );
                  })}
                </Flex>
              </Box>
            </Flex>
          </Box>
        )}
      </Box>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    theme: state.ThemeReducer.theme,
    requestAuditModal: state.RequestAuditModalReducer.auditModalOpened,
  };
};

export default connect(mapStateToProps, {
  changeTheme: changeTheme,
  changeAuditModalOpenedRedux: changeAuditModalOpened,
})(MobileMenu);
