import React from "react";
import { Icon } from "ui/atoms";
import Icons from "assets/icons";

interface ICheckBoxProps {
  unCheckedIcon?: string;
  checkedIcon?: string;
  checked?: boolean;
}

const CheckBox: React.FC<ICheckBoxProps> = ({
  unCheckedIcon = Icons.star,
  checkedIcon = Icons.starBlack,
  checked = false,
}) => {
  return (
    <Icon
      icon={checked ? checkedIcon : unCheckedIcon}
      size="MEDIUM"
    />
  );
};

export default CheckBox;
