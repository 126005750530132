import React, { useState } from "react";
import { Box, Input, Icon } from "ui/atoms";
import Icons from "assets/icons";
import { Z_INDEX_LEVELS } from "config/dimensions";
import { COLORS } from "config/colors";
import useWindowDimensions from "hooks/useWindowDimensions";
import { WINDOW_SIZES } from "config/dimensions";

interface ISearchInputProps {
  placeholder?: string;
  size?: "small" | "medium";
  color?: string;
  extend?: boolean;
  extendChangable?: boolean;
  width?: number | string;
  getter: Function;
}

const SearchInput: React.FC<ISearchInputProps> = ({
  placeholder = "Input search text here",
  size = "medium",
  color = COLORS.BLUE_THEME_GREY,
  extend = true,
  extendChangable = false,
  width = 0,
  getter,
}) => {
  const [searchText, setSearchText] = useState<string>("");
  const [extendState, setExtendState] = useState<boolean>(extend);
  const { deviceWidth } = useWindowDimensions();
  const onClickSearchButton = () => {
    getter(searchText);
  };

  return (
    <Box
      position="relative"
      zIndex={Z_INDEX_LEVELS.MAXIMUM}
      className="molecule-searchinput"
      borderColor={COLORS.BLUE_THEME_GREY}
      borderWidth={1}
      borderRadius={10}
      width={width ? width : deviceWidth >= WINDOW_SIZES.SIZE_768 ? 360 : "100%"}
      onMouseEnter={() => {
        if (extendChangable) {
          setExtendState(true);
        }
      }}
      onMouseLeave={() => {
        if (extendChangable) {
          setExtendState(false);
        }
      }}
    >
      <Box
        position="absolute"
        paddingVertical={size === "small" ? 13 : 10}
        paddingHorizontal={size === "small" ? 20 : 15}
      >
        <Icon
          icon={Icons.search}
          size={size === "small" ? "SMALL" : "MEDIUM"}
        />
      </Box>
      <Input
        width={extendState ? "100%" : "0px"}
        padding={"12px 20px 12px 40px"}
        className={size}
        color={color}
        value={searchText}
        onChange={(evt) => {
          setSearchText(evt.target.value);
          getter(evt.target.value)
        }}
        placeholder={placeholder}
        onPressEnter={() => {
          onClickSearchButton();
        }}
      />
    </Box>
  );
};

export default SearchInput;
