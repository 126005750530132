import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { Layout } from "ui/atoms";
import {
  Home,
  Founders,
  Investors,
  Dealflow,
  Ethereum,
  Polygon,
  Tron,
  Company,
  Disclaimer,
  Privacy,
  Terms,
  Requests,
  // SignIn,
  // Settings,
} from "ui/pages";
import "styles/index.scss";
// import AdminRequests from "ui/pages/adminRequests";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/signin" element={<SignIn />} /> */}
        {/* <Route path="/" element={<AdminLayout />}>
          <Route path="/admin-requests" element={<AdminRequests />} />
          <Route path="/admin-settings" element={<Settings />} />
        </Route> */}
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="/founders-raising-funds" element={<Founders />} />
          <Route path="/investors-raising-funds" element={<Investors />} />
          <Route path="/investors-deploying-capital" element={<Dealflow />} />
          <Route path="/ethereum" element={<Ethereum />} />
          <Route path="/polygon" element={<Polygon />} />
          <Route path="/tron" element={<Tron />} />
          <Route path="/company" element={<Company />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/requests" element={<Requests />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
