import { useState, useEffect } from 'react';
import { isIOS, isMacOs, isIPhone13, isWinPhone, isIPad13, isWindows } from "react-device-detect";

export default function useDeviceType() {
  const [deviceType, setDeviceType] = useState();

  useEffect(() => {
    if (isIOS || isIPhone13) {
        setDeviceType("iOS");
    } else if (isMacOs) {
        setDeviceType("Mac");
    } else if (isWindows) {
        setDeviceType("Windows");
    } else if (isWinPhone) {
        setDeviceType("WinPhone");
    } else if (isIPad13) {
        setDeviceType("iPad");
    } else {
        setDeviceType("Extra"); // Black Berry, iPod
    }
  }, []);

  return deviceType;
}
