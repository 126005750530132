import React from "react";
import {
  Box,
  Flex,
  Text,
  Container,
  ContentWrapper,
  Image,
  Icon,
} from "ui/atoms";
import { Page, Button, Accordian } from "ui/molecules";
// import Icons from "assets/icons";
import { COLORS } from "config/colors";
import { Z_INDEX_LEVELS } from "config/dimensions";
import useWindowDimensions from "hooks/useWindowDimensions";
import { WINDOW_SIZES } from "config/dimensions";
import { connect } from "react-redux";
import { changeTheme } from "redux/actions/theme";
import { changeAuditModalOpened } from "redux/actions/requestAuditModal";
import { tronFaqData } from "config/tronFaq";
import { fixedScrollBarLeftCalculate } from "config/formulas";
import Icons from "assets/icons";
import {Popup} from 'react-typeform-embed';

const Tron = (props: any) => {
  const { deviceWidth } = useWindowDimensions();

  return (
    <Page backgroundColor={COLORS.BLUE_THEME_DARK_BLUE}>
      <Flex flexDirection="column" gap={0} alignItems="center">
        <Box
          position="fixed"
          top={105}
          left={fixedScrollBarLeftCalculate(deviceWidth)}
          width={6}
          height="250px"
          zIndex={Z_INDEX_LEVELS.VERY_LOW}
          backgroundColor={COLORS.BLUE_THEME_BUTTON_BLUE}
        />
        <ContentWrapper
          backgroundColor={COLORS.DARK_THEME_GRAY_BACKGROUND}
          backgroundImage={"linear-gradient(to top right, #223244, #141923)"}
        >
          <Container>
            <Box padding={deviceWidth > WINDOW_SIZES.SIZE_768 ? 90 : 20}></Box>
            <Box
              paddingHorizontal={deviceWidth > WINDOW_SIZES.SIZE_1280 ? 150 : 0}
              paddingVertical={50}
            >
              <Flex flexDirection="column" alignItems="center" gap={20}>
              <Box paddingVertical={20}/>
                <Box paddingVertical={15} paddingHorizontal={10}>
                  <Flex flexDirection="column" alignItems="center" gap={30}>
                    <Text center type={"sublogo"} fontWeight={600}>
                      Tron Smart Contract Audit
                    </Text>
                    <Flex justifyContent="center" alignItems="center">
                      <Text
                        center
                        type="subheadtitle"
                        category="span"
                        fontWeight={600}
                        color={COLORS.BLUE_THEME_CHALK_BLUE}
                      >
                        Thorough code review and analysis to find and fix all
                        weaknesses in your Tron project.
                      </Text>
                    </Flex>
                  </Flex>
                </Box>
                <Box maxWidth={1000}>
                <Image image={Icons.tronlogo} width={deviceWidth > WINDOW_SIZES.SIZE_768 ? "400" : "200"} />
                </Box>
                <Box
                  backgroundColor={COLORS.DARK_THEME_TRANSPARENT}
                  borderRadius={5}
                  maxWidth={1000}
                  cursor
                >
                  <Flex alignItems="center" flexDirection="column">
                    <Box
                      backgroundColor={COLORS.BLUE_THEME_DEEP_BLUE}
                      backgroundImage={COLORS.BLUE_THEME_DEEP_GRADIENT}
                       minWidth={deviceWidth > WINDOW_SIZES.SIZE_1280 ? 400 : 0}
                      borderRadius={5}
                      paddingVertical={25}
                      paddingHorizontal={
                        deviceWidth > WINDOW_SIZES.SIZE_1280 ? 100 : 20
                      }
                    >
                      <Text
                        center
                        type="uppersubtitle"
                        fontWeight={600}
                        color={COLORS.BLUE_THEME_WHITE}
                      >
                        Protect Your Tron Project From Vulnerabilities
                      </Text>
                    </Box>
                  </Flex>
                </Box>
              </Flex>
            </Box>
          </Container>
          <Container>
            <Box padding={deviceWidth > WINDOW_SIZES.SIZE_768 ? 90 : 20}></Box>
            <Box
              paddingHorizontal={deviceWidth > WINDOW_SIZES.SIZE_1024 ? 120 : 20}//1024 ? 110 : 20}
              paddingVertical={0}
            >
              <Flex flexDirection="column" alignItems="center" gap={10}>
                <Box>
                  <Text center type={"title"} fontWeight={600}>
                    About Tron
                  </Text>
                </Box>
                <Box padding={20}></Box>
                <Flex
                  gap={30}
                  flexDirection={
                    deviceWidth > WINDOW_SIZES.SIZE_1280 ? "row" : "column"
                  }
                >
                  <Flex alignItems="center" flexDirection="column">
                    <Box
                      backgroundColor={COLORS.BLUE_THEME_DEEP_BLUE}
                      backgroundImage={COLORS.BLUE_THEME_DEEP_GRADIENT}
                       minWidth={deviceWidth > WINDOW_SIZES.SIZE_1280 ? 400 : 0}
                      minHeight={deviceWidth > WINDOW_SIZES.SIZE_1280 ? 420 : 0}
                      borderRadius={5}
                      paddingVertical={25}
                      paddingHorizontal={
                        deviceWidth > WINDOW_SIZES.SIZE_1280 ? 100 : 20
                      }
                    >
                      <Flex justifyContent="center">
                        <Box padding={20}>
                          <Image image={Icons.tronIcon} width="80" />
                        </Box>
                      </Flex>
                      <Text
                        center
                        type="uppersubtitle"
                        fontWeight={600}
                        color={COLORS.BLUE_THEME_WHITE}
                      >
                        Tron
                      </Text>
                      <Box padding={10}></Box>
                      <Text
                        center
                        lineHeight={1.6}
                        type="undersubtitle"
                        color={COLORS.BLUE_THEME_WHITE_BLUE}
                        fontWeight={600}
                      >
                        Tron is a blockchain-based decentralized digital
                        platform that primarily hosts entertainment
                        applications.
                      </Text>
                    </Box>
                  </Flex>
                  <Flex alignItems="center" flexDirection="column">
                    <Box
                      backgroundColor={COLORS.BLUE_THEME_DEEP_BLUE}
                      backgroundImage={COLORS.BLUE_THEME_DEEP_GRADIENT}
                       minWidth={deviceWidth > WINDOW_SIZES.SIZE_1280 ? 400 : 0}
                      minHeight={deviceWidth > WINDOW_SIZES.SIZE_1280 ? 420 : 0}
                      borderRadius={5}
                      paddingVertical={25}
                      paddingHorizontal={
                        deviceWidth > WINDOW_SIZES.SIZE_1280 ? 100 : 20
                      }
                    >
                      <Flex justifyContent="center">
                        <Box padding={20}>
                          <Image image={Icons.utxo} width="80" />
                        </Box>
                      </Flex>
                      <Text
                        center
                        type="uppersubtitle"
                        fontWeight={600}
                        color={COLORS.BLUE_THEME_WHITE}
                      >
                        UTXO
                      </Text>
                      <Box padding={10}></Box>
                      <Text
                        center
                        lineHeight={1.6}
                        type="undersubtitle"
                        color={COLORS.BLUE_THEME_WHITE_BLUE}
                        fontWeight={600}
                      >
                        The transaction of TRX coins takes place on a public
                        ledger. TRX uses a transaction model which is similar to
                        that of Bitcoin’s, called UTXO.
                      </Text>
                    </Box>
                  </Flex>
                  <Flex alignItems="center" flexDirection="column">
                    <Box
                      backgroundColor={COLORS.BLUE_THEME_DEEP_BLUE}
                      backgroundImage={COLORS.BLUE_THEME_DEEP_GRADIENT}
                       minWidth={deviceWidth > WINDOW_SIZES.SIZE_1280 ? 400 : 0}
                      minHeight={deviceWidth > WINDOW_SIZES.SIZE_1280 ? 420 : 0}
                      borderRadius={5}
                      paddingVertical={25}
                      paddingHorizontal={
                        deviceWidth > WINDOW_SIZES.SIZE_1280 ? 100 : 20
                      }
                    >
                      <Flex justifyContent="center">
                        <Box padding={20}>
                          <Image image={Icons.pyramid} width="80" />
                        </Box>
                      </Flex>
                      <Text
                        center
                        type="uppersubtitle"
                        fontWeight={600}
                        color={COLORS.BLUE_THEME_WHITE}
                      >
                        DPoS
                      </Text>
                      <Box padding={10}></Box>
                      <Text
                        center
                        lineHeight={1.6}
                        type="undersubtitle"
                        color={COLORS.BLUE_THEME_WHITE_BLUE}
                        fontWeight={600}
                      >
                        TRON relies on a Delegated-Proof-of-Stake
                        (DPoS) consensus mechanism that allows users to earn
                        passive income through staking.
                      </Text>
                    </Box>
                  </Flex>
                </Flex>
                <Box padding={30}></Box>
              </Flex>
            </Box>
          </Container>
          <Container>
            <Box padding={deviceWidth > WINDOW_SIZES.SIZE_768 ? 70 : 20}></Box>
            <Box
              paddingHorizontal={deviceWidth > WINDOW_SIZES.SIZE_1024 ? 120 : 20}//1024 ? 110 : 20}
            >
              <Flex flexDirection="column" alignItems="center" gap={10}>
                <Box paddingVertical={5}>
                  <Text center type={"title"} fontWeight={600}>
                    What does a Tron Audit include?
                  </Text>
                </Box>
                <Box padding={10}></Box>
                <Box paddingVertical={5}>
                  <Text center type={"uppersubtitle"} fontWeight={600}>
                    Once we complete your audit, we will provide you with a
                    comprehensive audit report in pdf format as well as:
                  </Text>
                </Box>
                <Box padding={20}></Box>
                <Flex
                  gap={30}
                  flexDirection={
                    deviceWidth > WINDOW_SIZES.SIZE_1280 ? "row" : "column"
                  }
                >
                  <Flex alignItems="center" flexDirection="column">
                    <Box
                      backgroundColor={COLORS.BLUE_THEME_DEEP_BLUE}
                      backgroundImage={COLORS.BLUE_THEME_DEEP_GRADIENT}
                       minWidth={deviceWidth > WINDOW_SIZES.SIZE_1280 ? 400 : 0}
                      minHeight={deviceWidth > WINDOW_SIZES.SIZE_1280 ? 330 : 0}
                      borderRadius={5}
                      paddingVertical={25}
                      paddingHorizontal={
                        deviceWidth > WINDOW_SIZES.SIZE_1280 ? 50 : 20
                      }
                    >
                      <Flex justifyContent="center">
                        <Box padding={20}>
                          <Icon icon={Icons.checkBlue} size="SUPER_LARGE" />
                        </Box>
                      </Flex>
                      <Text
                        center
                        type="uppersubtitle"
                        fontWeight={600}
                        color={COLORS.BLUE_THEME_WHITE}
                      >
                        Authentication Badge
                      </Text>
                      <Box padding={10}></Box>
                      <Text
                        center
                        lineHeight={1.6}
                        type="undersubtitle"
                        color={COLORS.BLUE_THEME_WHITE_BLUE}
                        fontWeight={600}
                      >
                        After you are audited with proof, you will be able to
                        integrate the badge “Audited by Proof” into your
                        website.
                      </Text>
                    </Box>
                  </Flex>
                  <Flex alignItems="center" flexDirection="column">
                    <Box
                      backgroundColor={COLORS.BLUE_THEME_DEEP_BLUE}
                      backgroundImage={COLORS.BLUE_THEME_DEEP_GRADIENT}
                       minWidth={deviceWidth > WINDOW_SIZES.SIZE_1280 ? 400 : 0}
                      minHeight={deviceWidth > WINDOW_SIZES.SIZE_1280 ? 330 : 0}
                      borderRadius={5}
                      paddingVertical={25}
                      paddingHorizontal={
                        deviceWidth > WINDOW_SIZES.SIZE_1280 ? 50 : 20
                      }
                    >
                      <Flex justifyContent="center">
                        <Box padding={20}>
                          <Icon icon={Icons.checkBlue} size="SUPER_LARGE" />
                        </Box>
                      </Flex>
                      <Text
                        center
                        type="uppersubtitle"
                        fontWeight={600}
                        color={COLORS.BLUE_THEME_WHITE}
                      >
                        Website Feature
                      </Text>
                      <Box padding={10}></Box>
                      <Text
                        center
                        lineHeight={1.6}
                        type="undersubtitle"
                        color={COLORS.BLUE_THEME_WHITE_BLUE}
                        fontWeight={600}
                      >
                        Feature your project and audit on our website and our
                        Github as irrefutable Proof that you were audited.
                      </Text>
                    </Box>
                  </Flex>
                  <Flex alignItems="center" flexDirection="column">
                    <Box
                      backgroundColor={COLORS.BLUE_THEME_DEEP_BLUE}
                      backgroundImage={COLORS.BLUE_THEME_DEEP_GRADIENT}
                       minWidth={deviceWidth > WINDOW_SIZES.SIZE_1280 ? 400 : 0}
                      minHeight={deviceWidth > WINDOW_SIZES.SIZE_1280 ? 330 : 0}
                      borderRadius={5}
                      paddingVertical={25}
                      paddingHorizontal={
                        deviceWidth > WINDOW_SIZES.SIZE_1280 ? 50 : 20
                      }
                    >
                      <Flex justifyContent="center">
                        <Box padding={20}>
                          <Icon icon={Icons.checkBlue} size="SUPER_LARGE" />
                        </Box>
                      </Flex>
                      <Text
                        center
                        type="uppersubtitle"
                        fontWeight={600}
                        color={COLORS.BLUE_THEME_WHITE}
                      >
                        Marketing Partnership
                      </Text>
                      <Box padding={10}></Box>
                      <Text
                        center
                        lineHeight={1.6}
                        type="undersubtitle"
                        color={COLORS.BLUE_THEME_WHITE_BLUE}
                        fontWeight={600}
                      >
                        After your smart contract audit, you will get an
                        extended social media/email marketing package.
                      </Text>
                    </Box>
                  </Flex>
                </Flex>
                <Box padding={50}></Box>
              </Flex>
            </Box>
          </Container>
          <Container>
            <Box padding={30}></Box>
            <Box
              paddingHorizontal={deviceWidth > WINDOW_SIZES.SIZE_1280 ? 120 : 0}
              paddingVertical={50}
            >
              <Flex flexDirection="column" alignItems="center" gap={20}>
                <Box padding={5}>
                  <Text type="title" fontWeight={600} center>
                    FAQ
                  </Text>
                </Box>
                <Accordian contents={tronFaqData} />
              </Flex>
            </Box>
            <Box padding={50}></Box>
            <Box paddingHorizontal={20} paddingVertical={50}>
              <Flex flexDirection="column" alignItems="center" gap={20}>
                <Text center type="title" fontWeight={600}>
                  Interested In A Proof Security Audit?
                </Text>
                <Box padding={20}>
                <Button
                    backgroundColor={COLORS.BLUE_THEME_BUTTON_BLUE}
                    backgroundHoverColor={COLORS.DROPDOWN_HOVER}
                    shadow={false}
                    onClick={() => {window.location.replace("https://calendly.com/caduceuscapital/preconsultation");}}
                  >
                    FREE PRECONSULTATION
                  </Button>
                </Box>
              </Flex>
            </Box>
            <Box padding={50}></Box>
          </Container>
        </ContentWrapper>
      </Flex>
    </Page>
  );
};

const mapStateToProps = (state: any) => {
  return {
    theme: state.ThemeReducer.theme,
    requestAuditModal: state.RequestAuditModalReducer.auditModalOpened,
  };
};

export default connect(mapStateToProps, {
  changeTheme: changeTheme,
  changeAuditModalOpened: changeAuditModalOpened,
})(Tron);
