import React, { useEffect, useState } from "react";

import { Box, Flex, Icon } from "ui/atoms";
import Icons from "assets/icons";
import PaginatorItem from "./paginatorItem";
import { PAGINATOR_ARROW } from "config/dimensions";

interface IPaginatorProps {
  pagesCount: number;
  currentPageIndex: number;
  getter: Function;
}

const Paginator: React.FC<IPaginatorProps> = ({
  pagesCount,
  currentPageIndex,
  getter,
}) => {
  const [currentPage, setCurrentPage] = useState<number>(currentPageIndex);
  let pageGenerator = [];

  useEffect(() => {
    getter(currentPage);
  }, [currentPage, getter]);

  if (pagesCount < 7) {
    for (let i = 1; i <= pagesCount; ++i) {
      pageGenerator.push(i);
    }
  }

  return (
    <Flex gap={5} alignItems="center">
      <Box
        padding={10}
        onClick={() => {
          if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
          }
        }}
      >
        <Icon icon={Icons.left}></Icon>
      </Box>
      {pagesCount < 7 &&
        pageGenerator.map((item, index) => {
          return (
            <PaginatorItem
              key={index}
              itemText={index + 1}
              setCurrentPage={setCurrentPage}
              currentPageIndex={currentPage}
            />
          );
        })}
      {pagesCount >= 7 && <Flex gap={5} alignItems="center">
        <PaginatorItem
          itemText={1}
          setCurrentPage={setCurrentPage}
          currentPageIndex={currentPage}
        />
        {currentPage > 3 && (
          <PaginatorItem
            itemText={PAGINATOR_ARROW.left}
            setCurrentPage={setCurrentPage}
            currentPageIndex={currentPage}
          />
        )}
        <Flex gap={5}>
          {currentPage === pagesCount && (
            <PaginatorItem
              itemText={currentPage - 2}
              setCurrentPage={setCurrentPage}
              currentPageIndex={currentPage}
            />
          )}
          {currentPage !== 1 && currentPage !== 2 && (
            <PaginatorItem
              itemText={currentPage - 1}
              setCurrentPage={setCurrentPage}
              currentPageIndex={currentPage}
            />
          )}
          {currentPage !== 1 && currentPage !== pagesCount && (
            <PaginatorItem
              itemText={currentPage}
              setCurrentPage={setCurrentPage}
              currentPageIndex={currentPage}
            />
          )}
          {currentPage !== pagesCount - 1 && currentPage !== pagesCount && (
            <PaginatorItem
              itemText={currentPage + 1}
              setCurrentPage={setCurrentPage}
              currentPageIndex={currentPage}
            />
          )}
          {currentPage === 1 && (
            <PaginatorItem
              itemText={currentPage + 2}
              setCurrentPage={setCurrentPage}
              currentPageIndex={currentPage}
            />
          )}
        </Flex>
        {currentPage <= pagesCount - 3 && (
          <PaginatorItem
            itemText={PAGINATOR_ARROW.right}
            setCurrentPage={setCurrentPage}
            currentPageIndex={currentPage}
          />
        )}
        <PaginatorItem
          itemText={pagesCount}
          setCurrentPage={setCurrentPage}
          currentPageIndex={currentPage}
        />
      </Flex>}
      <Box
        padding={10}
        onClick={() => {
          if (currentPage < pagesCount) {
            setCurrentPage(currentPage + 1);
          }
        }}
      >
        <Icon icon={Icons.right}></Icon>
      </Box>
    </Flex>
  );
};

export default Paginator;
