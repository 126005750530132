import React, { useState } from "react";
import { Box, Flex, Text, Icon } from "ui/atoms";
import { COLORS } from "config/colors";
import Icons from "assets/icons";
import useWindowDimensions from "hooks/useWindowDimensions";
import { WINDOW_SIZES } from "config/dimensions";

interface IAccordianContentTypes {
  title: string;
  content: string;
  collapse: boolean;
}

interface IAccordianProps {
  contents: Array<IAccordianContentTypes>;
}

const Accordian: React.FC<IAccordianProps> = ({ contents }) => {
  const [content, setContent] = useState<Array<IAccordianContentTypes>>(
    contents
  );
  const { deviceWidth } = useWindowDimensions();
  return (
    <Box
      width="100%"
      backgroundColor={COLORS.DARK_THEME_TRANSPARENT}
      cursor
    >
      <Flex flexDirection="column" gap={10}>
        {content.length === 0 && (
          <Box backgroundColor={COLORS.DARK_THEME_GRAY_BACKGROUND} padding={50}>
            <Text type="subtitle">"No FAQs"</Text>
          </Box>
        )}
        {content.length > 0 &&
          content.map((contentItem, index) => {
            return (
              <Box
                backgroundColor={COLORS.BLACK_THEME_DEEP_GREEN}
                borderColor={"#D8EE81"}
                borderWidth={0}
                borderRadius={10}
                //boxShadow={"3px 3px 3px #0A0E1391"}
                padding={30}
                onClick={() => {
                  const newContent = content.map((contentItem, idx) => {
                    if (idx === index)
                      contentItem.collapse = !contentItem.collapse;
                    else contentItem.collapse = true;
                    return contentItem;
                  });
                  setContent(newContent);
                }}
                key={index}
              >
                <Box transition="all 1s" cursor>
                  <Flex gap={0} flexDirection="column" justifyContent="center">
                    <Flex alignItems="center" gap={15}>
                      <Text type="logo" color={COLORS.LIGHT_BLUE}>
                        {!contentItem.collapse ? (
                          <Icon icon={Icons.upMini} size="MEDIUM"></Icon>
                        ) : (
                          <Icon icon={Icons.right} size="MEDIUM"></Icon>
                        )}
                      </Text>
                      <Text type="subtitle" fontWeight={600} lineHeight={1.3}>
                        {contentItem.title}
                      </Text>
                    </Flex>
                    <Box
                      overflowX="hidden"
                      overflowY="hidden"
                      maxHeight={
                        !contentItem.collapse
                          ? deviceWidth > WINDOW_SIZES.SIZE_768
                            ? "200px"
                            : "350px"
                          : "0px"
                      }
                      transition="all 0.4s"
                    >
                      <Box padding={15}/>
                      <Text type="undersubtitle" fontWeight={600} lineHeight={1.3} color={COLORS.BLACK_THEME_WHITE_GREEN}>
                        {contentItem.content}
                      </Text>
                    </Box>
                  </Flex>
                </Box>
              </Box>
            );
          })}
      </Flex>
    </Box>
  );
};

export default Accordian;
