export const faq2Data = [
  {
    title: "How do I build a strong network of potential limited partners (LPs)?",
    content: `Building a strong network of potential LPs can be achieved through leveraging personal and professional connections, participating in industry events, engaging with industry associations and organizations and many other ways. Caduceus provides guidance and helps to connect you with the right resources to help you build a strong network of potential LPs.`,
    collapse: true
  },
  {
    title: "What are the key considerations when structuring a venture fund?",
    content: `Key considerations when structuring a venture fund include fund size, fund duration, and fund terms such as management fees, carried interest, and other provisions. Caduceus can guide you through the process of structuring a venture fund and help you to create a fund that is well-suited to your goals.`,
    collapse: true
  },
  {
    title: "How do I create a compelling pitch to potential LPs?",
    content: `Creating a compelling pitch to potential LPs will involve clearly articulating your investment thesis, demonstrating your expertise, highlighting your team's skills and experience, and providing details about your investment strategy. We can help you to create a pitch-perfect compelling pitch deck that will ensure you attract the right LPs.`,
    collapse: true
  },
  {
    title: "How do I set realistic fundraising targets and timelines?",
    content: `Setting realistic fundraising targets and timelines involves considering the size and focus of your fund as larger, more generalist funds may have an easier time raising capital than smaller, more specialized funds. You will also need to consider your team's track record, and the current market environment. Caduceus can help you to set realistic fundraising targets and timelines.`,
    collapse: true
  },
  {
    title:
      "How do I create and manage a fund budget?",
    content: `Creating and managing a fund budget might involve setting investment and operating budget targets, tracking expenses and revenue against those targets, and adjusting the budget as needed to ensure that the fund stays on track. We offer guidance on best practises for creating and managing a fund budget.`,
    collapse: true
  },
  {
    title: "How do I create and execute a strong investment strategy?",
    content: `Creating and executing a strong investment strategy involves defining the fund's focus and target market, identifying key trends and opportunities in the market, and developing a process for sourcing, evaluating, and executing investments. Caduceus provides guidance on how to create and execute a strong investment strategy.`,
    collapse: true
  }
];
