export const COLORS = {
  INFO: "#057642",
  WARNING: "#FF9900",
  DANGER: "#CC3300",
  DANGER_LIGHT: "#EE5522",
  GREEN: "#13A05F",
  TYPICAL_RED: "#FF5544",
  TIP: "#FEE5FA",
  TIP_DARK: "#FA7574",
  DROPDOWN_HOVER: "#2B10FF",
  POSTBOX_BACK: "#161922",
  SWITCH_BLUE: "#2C60E6",
  SWITCH_WHITE: "#E6E5E4",
  TRANSPARENT_WHITE: "#FFFFFF50",
  B_BLACK: "#BBB",
  TH_BLACK: "#333",
  BLACK: "#000000",
  DARK: "#222222",
  ALARM: "#FFFFFF",
  WHITE: "#FFFFFF",
  GRAY: "#DDDDDD",
  GRAY_LIGHT: "#EEE",
  GRAY_DARK: "#555",
  BLUE: "#0A66C2",
  LIGHT_BLUE: "#0057FF",
  TYPICAL_BLUE: "#0085FF",
  GREY: "#E8E8E8",
  BORDER: "#444444",
  TEXT_PRIMARY_LIGHT: "#000000",
  TEXT_SECONDARY_LIGHT: "#666666",
  TEXT_PRIMARY_DARK: "#FFFFFF",
  TEXT_SECONDARY_DARK: "#BBBBBB",
  BORDER_LIGHT: "#BBBBBB",
  BORDER_DARK: "#444444",
  HEADER_LIGHT: "#163D85",
  HEADER_DARK: "#222222",
  TRANSPARENT: "transparent",

  /* Dark Theme Color Table */
  DARK_THEME_BLACK: "#161616",
  DARK_THEME_BLACK_TRANSPARENT: "#16161690",
  DARK_THEME_WHITE: "#FFFFFF",
  DARK_THEME_BUTTON_BLUE: "#1B00FF",
  DARK_THEME_BUTTON_BLUE_DARK: "#1201A8",
  DARK_THEME_GRAY_BACKGROUND: "#191919",
  DARK_THEME_GRAY_BACKGROUND_DARK: "#151515",
  DARK_THEME_TRANSPARENT: "transparent",
  DARK_THEME_GREY_BACKGROUND: "#1C1C1C",
  DARK_THEME_BORDER: "#434343",

   /* Black Theme Color Table */
   BLACK_THEME_WHITE: "#FFFFFF",
   BLACK_THEME_BLACK: "#000000",
   BLACK_THEME_GREEN: "#b1de04",
   BLACK_THEME_DEEP_GREEN: "#7B9B02",
   BLACK_THEME_DEEP_GREEN_TRANSPARENT: "#586F02EF",
   BLACK_THEME_DIM_GREEN_TRANSPARENT: "#586F02FC",
   BLACK_THEME_DIM_GREEN: "#465801",
   BLACK_THEME_CHALK_GREEN: "#335400",
   BLACK_THEME_GREEN_TRANSPARENT: "#b1de0405",
   BLACK_THEME_WHITE_GREEN: "#EFF8CC",
   BLACK_THEME_GRADIENT_GREEN: "linear-gradient(to left, #465801, #6A8502)",
   BLACK_THEME_GRADIENT_DEEP_GREEN: "linear-gradient(to top left, #465801, #8DB103)",




  /* Blue Theme Color Table */
  BLUE_THEME_WHITE: "#FFFFFF",
  BLUE_THEME_BUTTON_BLUE: "#1B00FF",
  BLUE_THEME_BUTTON_BLUE_DARK: "#1201A8",
  BLUE_THEME_DARK_BLUE: "#141923",
  BLUE_THEME_NAVY_BLUE: "#171E27",
  BLUE_THEME_DEEP_BLUE: "#1D2633",
  BLUE_THEME_MEDIUM_BLUE: "#2A4056",
  BLUE_THEME_CHALK_BLUE: "#A7B3CF",
  BLUE_THEME_WHITE_BLUE: "#C8D7EA",
  BLUE_THEME_IRON_BLUE:"#223143",
  BLUE_THEME_GREY: "#636975",
  BLUE_THEME_DARK_GREY: "#333C4E",
  BLUE_THEME_TRANSPARENT: "transparent",
  BLUE_THEME_BLUE_TRANSPARENT: "#10152078",
  BLUE_THEME_BORDER: " #7E859352",
  BLUE_THEME_GRADIENT_BLUE: "linear-gradient(to left, #242933, #2A4056)",
  BLUE_THEME_DEEP_GRADIENT: "linear-gradient(to bottom right, #2A3F55, #1D2633, #1D2633)",
  BLUE_THEME_TEAL: "#3DC2CD",
  BLUE_THEME_PURPLE: "#7F8DE3",
  BLUE_THEME_GREEN: "#64E6AF",
  BLUE_THEME_TEAL_DARK: "#35a8b2",
  BLUE_THEME_PURPLE_DARK: "#6c77c0",
  BLUE_THEME_GREEN_DARK: "#54c595",
  BLUE_THEME_TABLE_EVEN: "#242E3C",
  BLUE_THEME_TABLE_ODD: "#1C2430",
};