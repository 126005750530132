import React from "react";

interface ISelectProps {
    getter: Function,
    options: Array<any>,
};

const Select:React.FC<ISelectProps> = ({
    getter,
    options,
}) => {
  return (
    <select
      onChange={(evt) => {
        getter(Number(evt.target.value));
      }}
      defaultValue={options && options.length > 0 ? options.at(1) : "initial"}
    >
      {
        options.map((optionItem, index) => {
          return <option key={index} value={optionItem}>
            {optionItem}
          </option>
        })
      }
    </select>
  );
};

export default Select;
