import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { changeTheme } from "redux/actions/theme";
import { changeAuditModalOpened } from "redux/actions/requestAuditModal";
import { Box, Container, Icon, Flex, Text, Input, TextArea } from "ui/atoms";
import { Button, MobileMenu, DropDown, Bell } from "ui/molecules";
import useWindowDimensions from "hooks/useWindowDimensions";
import useDeviceType from "hooks/useDeviceTypes";
import Icons from "assets/icons";
import { WINDOW_SIZES, Z_INDEX_LEVELS } from "config/dimensions";
import { COLORS } from "config/colors";
import Modal from "react-modal";
// import { ToastContainer } from "react-toastify/dist/components";
// import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Header = (props: any) => {
  const { deviceWidth } = useWindowDimensions();
  const deviceType = useDeviceType();
  const [modalIsOpen, setIsOpen] = useState<boolean>(false);
  const [darkMode, setDarkMode] = useState<boolean>(false);
  const [allowRedAlert, setAllowRedAlert] = useState<boolean>(false);
  // const navigate = useNavigate();

  useEffect(() => {
    if (props.requestAuditModal) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [props.requestAuditModal]);

  const openModal = () => {
    setIsOpen(true);
    props.changeAuditModalOpened(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    props.changeAuditModalOpened(false);
  };
  const customStyles: Modal.Styles = {
    content: {
      top: "7%",
      left: deviceWidth > WINDOW_SIZES.SIZE_768 ? "35%" : "5%",
      right: deviceWidth > WINDOW_SIZES.SIZE_768 ? "35%" : "5%",
      bottom: "0%",
      transition: "1s",
      minHeight: 600,
      backgroundColor: "#1D2633",
      boxSizing: "border-box",
      border: "none",
      boxShadow: "",
      borderRadius: 4,
      padding: 0,
      zIndex: 2000,
      height: "fit-content",
    },
    overlay: {
      position: "fixed",
      padding: 20,
      top: "0%",
      left: "0%",
      right: "0%",
      bottom: "0%",
      overflowY: "scroll",
      zIndex: 1999,
      backgroundColor: "#00000080",
    },
  };
  Modal.setAppElement("#root");

  // const refEmail = useRef<HTMLInputElement>(null);
  const [mail, setMail] = useState<string>("");
  const [projectName, setProjectName] = useState<string>("");
  const [chatTool, setChatTool] = useState<string>("");
  const [contact, setContact] = useState<string>("");
  const [codeLink, setCodeLink] = useState<string>("");
  const [message, setMessage] = useState<string>("");

  return (
    <>
      <Box
        className="organism-header"
        paddingVertical={20}
        backgroundColor={COLORS.BLACK_THEME_GREEN_TRANSPARENT}
        zIndex={Z_INDEX_LEVELS.MAXIMUM}
        width="100%"
        backdropFilter={true}
      >
        <Container>
          <Box position="relative">
            <Flex justifyContent="space-between" alignItems="center">
              <Flex gap={50}>
                <Box
                  onClick={() => {
                    window.location.replace("/#");
                  }}
                >
                  {deviceWidth > WINDOW_SIZES.SIZE_768 ? (
                    <Icon icon={Icons.caduceusLogo} size="SUPER_LARGE" />
                  ) : (
                    <Icon icon={Icons.caduceusLogo} size="UPPERLARGE" />
                  )}
                </Box>
                {deviceWidth > WINDOW_SIZES.SIZE_1280 && (
                  <Flex
                    gap={10}
                    alignItems="center"
                    justifyContent="space-around"
                  > 
                    <Box position="relative" left={50}>
                      <DropDown
                        title="Services"
                        data={[
                          {
                            title: "Founders Raising Funds",
                            content:
                              "Rapidly reach your company's fundraising goals.",
                            url: "founders-raising-funds#",
                            icon: Icons.idea,
                          },
                          {
                            title: "Investors Raising Funds",
                            content:
                              "Connect with the right LPs to raise capital for your fund.",
                            url: "investors-raising-funds#",
                            icon: Icons.investment,
                          },
                          {
                            title: "Investors Deploying Capital",
                            content:
                              "Get access to high-quality dealflow.",
                            url: "investors-deploying-capital#",
                            icon: Icons.dealing,
                          },
                        ]}
                        direction="left"
                        top={-20}
                      />
                    </Box>
                    {/*
                    <Box position="relative" left={200}>
                    <DropDown
                        title="Portfolio"
                        data={[]}
                        baseUrl="/#"
                        direction="left"
                        top={-20}
                      />
                    </Box>
                    */}
                    <Box position="relative" left={200}>{/*<Box position="relative" left={350}>*/}
                      <DropDown
                        title="Company"
                        data={[]}
                        baseUrl="/company"
                        direction="left"
                        top={-20}
                      />
                    </Box>
                    <Box position="relative" left={350}>{/*<Box position="relative" left={500}>*/}
                      <DropDown
                        title="Socials"
                        data={[
                          {
                            title: "Discord",
                            content: "Connect with us on Discord",
                            url: "https://discordapp.com/users/1066783743425134622",
                            icon: Icons.discord,
                          },
                          {
                            title: "Twitter",
                            content: "Join the conversation on Twitter.",
                            url: "https://twitter.com/ConsultCaduceus",
                            icon: Icons.twitterSocial,
                          },
                          {
                            title: "LinkedIn",
                            content: "Find us on LinkedIn.",
                            url: "https://www.linkedin.com/company/consult-caduceus/",
                            icon: Icons.linkedinSocial,
                          },
                          {
                            title: "Medium",
                            content: "Check out our Medium.",
                            url: "https://medium.com/@consultcaduceus",
                            icon: Icons.medium,
                          },
                        ]}
                        direction="left"
                        top={-20}
                      />
                    </Box>
                    {/* <SearchInput
                      size="small"
                      placeholder="Input category here"
                      extend={false}
                      extendChangable={true}
                    /> */}
                  </Flex>
                )}
              </Flex>
              {deviceWidth > WINDOW_SIZES.SIZE_1280 && (
                <Flex gap={38} alignItems="center">
                  {/* <SearchInput size="small" /> */}
                  <Button
                    backgroundColor={COLORS.BLACK_THEME_DEEP_GREEN}
                    backgroundHoverColor={COLORS.BLACK_THEME_DIM_GREEN}
                    shadow={false}
                    onClick={() => {window.location.replace("https://calendly.com/caduceuscapital/preconsultation");}}
                  >
                    FREE PRECONSULTATION
                  </Button>
                </Flex>
              )}
              {deviceWidth <= WINDOW_SIZES.SIZE_1280 && (
                <Flex gap={15} alignItems="center">
                  <MobileMenu />
                </Flex>
              )}
            </Flex>
          </Box>
        </Container>
      </Box>
    </>
  );
};

const mapStateToProps = (state: any) => {
  console.log(state);
  return {
    theme: state.ThemeReducer.theme,
    requestAuditModal: state.RequestAuditModalReducer.auditModalOpened,
  };
};

export default connect(mapStateToProps, {
  changeTheme: changeTheme,
  changeAuditModalOpened: changeAuditModalOpened,
})(Header);
