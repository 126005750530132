export const polygonFaqData = [
  {
    title: "What is Polygon?",
    content: `Polygon is an interoperability layer two scaling solution for building Ethereum-compatible blockchains. Polygon effectively transforms Ethereum into a full-fledged multi-chain system. `,
    collapse: true
  },
  {
    title: "What is MATIC?",
    content: `The Polygon network uses the MATIC token for gas fees, governance, and staking via a modified proof of stake (PoS) consensus mechanism.`,
    collapse: true
  },
  {
    title: "What is Layer 2?",
    content: `Layer-two is a secondary framework or protocol that is built on top of an existing blockchain system. The main goal of these protocols is to solve transaction speed and scaling difficulties.`,
    collapse: true
  },
  {
    title: "How does Polygon Work?",
    content: `Being a layer-two scaling solution, Polygon uses a Proof of Stake (PoS) system of validators for asset security, and staking is an integral part of the ecosystem. Validators on the network stake their MATIC tokens as capital collateral in order to become part of the PoS consensus mechanism. Validators receive MATIC tokens in return.`,
    collapse: true
  },
  {
    title:
      "Do I get a new Polygon wallet address when I use the Polygon Network?",
    content: `No, your Polygon wallet address will be the same as the address that you use for your Ethereum mainnet wallet.`,
    collapse: true
  },
  {
    title: "How fast is Polygon",
    content: `Transactions on the Polygon Network are very fast as new blocks only take 2 seconds to confirm.`,
    collapse: true
  }
];
