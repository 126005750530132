import React from "react";
import {
  Box,
  Flex,
  Text,
  Container,
  ContentWrapper,
  Image,
  Icon,
} from "ui/atoms";
import { Page, Button, Accordian } from "ui/molecules";
// import Icons from "assets/icons";
import { COLORS } from "config/colors";
import { Z_INDEX_LEVELS } from "config/dimensions";
import useWindowDimensions from "hooks/useWindowDimensions";
import { WINDOW_SIZES } from "config/dimensions";
import { connect } from "react-redux";
import { changeTheme } from "redux/actions/theme";
import { changeAuditModalOpened } from "redux/actions/requestAuditModal";
import { faq2Data } from "config/faq2";
import { fixedScrollBarLeftCalculate } from "config/formulas";
import Icons from "assets/icons";
import { useState } from "react";

const Investors = (props: any) => {
  const { deviceWidth } = useWindowDimensions();
  const [isDone, setIsDone] = useState(false);
  const [isFinished, setIsFinished] = useState(false);

  const setFinished = () => {
    if (isDone) {
      setIsFinished(true);
    } 
  };

  return (
    <Page backgroundColor={COLORS.BLACK_THEME_BLACK}>
      <Flex flexDirection="column" gap={0} alignItems="center">
        <Box
          position="fixed"
          top={105}
          left={fixedScrollBarLeftCalculate(deviceWidth)}
          width={6}
          height="250px"
          zIndex={Z_INDEX_LEVELS.VERY_LOW}
          backgroundColor={COLORS.BLACK_THEME_BLACK}
        />
        <ContentWrapper
          backgroundColor={COLORS.BLACK_THEME_BLACK}
        >
          <Container>
            <Box padding={deviceWidth > WINDOW_SIZES.SIZE_768 ? 90 : 20}></Box>
            <Box paddingHorizontal={deviceWidth > WINDOW_SIZES.SIZE_960 ? 200 : 0} paddingVertical={deviceWidth > WINDOW_SIZES.SIZE_768 ? 50 : 100}>
              <Flex flexDirection="column" alignItems="center" gap={20}>
              <Box maxWidth="100%" overflowX="hidden">
                <Text center noWrap category="p" >
              <Text center type="title" fontFamily="GilroyBold" category="span">
              Easily&nbsp;
              </Text>
              <Text
                    center
                    type="title"
                    fontFamily="GilroyBold"
                    category="span"
                    color={COLORS.BLACK_THEME_GREEN}
                  >
                    secure capital&nbsp;
                  </Text>
                    <Text
                    center
                    type="title"
                    fontFamily="GilroyBold"
                    category="span"
                    color={COLORS.BLACK_THEME_WHITE}
                  >
                  for your fund by&nbsp;
                  </Text>
                   <Text
                    center
                    type="title"
                    fontFamily="GilroyBold"
                    category="span"
                    color={COLORS.BLACK_THEME_GREEN}
                  >
                    perfecting your pitch&nbsp;
                  </Text>
                    <Text
                    center
                    type="title"
                    fontFamily="GilroyBold"
                    category="span"
                    color={COLORS.BLACK_THEME_WHITE}
                  >
                   and&nbsp;
                  </Text>
                   <Text
                    center
                    type="title"
                    fontFamily="GilroyBold"
                    category="span"
                    color={COLORS.BLACK_THEME_GREEN}
                  >
                   connecting with LPs&nbsp;
                  </Text>
                    <Text
                    center
                    type="title"
                    fontFamily="GilroyBold"
                    category="span"
                    color={COLORS.BLACK_THEME_WHITE}
                  >
                    who are eager to invest in your success.
                  </Text>
                  </Text>
                  </Box>
                <Box padding={20} />
                <Box marginLeft={0} maxWidth={1000}>
                  <Image image={Icons.investorsraising} width={deviceWidth > WINDOW_SIZES.SIZE_768 ? "500" : "250"} />
                </Box>
                <Box
                  backgroundColor={COLORS.DARK_THEME_TRANSPARENT}
                  borderRadius={5}
                  maxWidth={1000}
                  cursor
                >
                </Box>
              </Flex>
            </Box>
          </Container>
          <Container>
            <Box
              paddingHorizontal={
                deviceWidth > WINDOW_SIZES.SIZE_1024 ? 110 : 20
              }
              paddingVertical={0}
            >
              <Flex flexDirection="column" alignItems="center" gap={10}>
                <Box>
                  <Text center type={"title"} fontWeight={600}>
                    How we help you raise capital.
                  </Text>
                </Box>
                <Box padding={20}></Box>
                <Flex
                  gap={30}
                  flexDirection={
                    deviceWidth > WINDOW_SIZES.SIZE_1280 ? "row" : "column"
                  }
                >
                  <Flex alignItems="center" flexDirection="column">
                    <Box
                      backgroundColor={COLORS.BLACK_THEME_BLACK}
                      backgroundImage={COLORS.BLACK_THEME_GRADIENT_DEEP_GREEN}
                      minWidth={deviceWidth > WINDOW_SIZES.SIZE_1280 ? 400 : 0}
                      minHeight={deviceWidth > WINDOW_SIZES.SIZE_1280 ? 420 : 0}
                      borderRadius={5}
                      paddingVertical={25}
                      paddingHorizontal={
                        deviceWidth > WINDOW_SIZES.SIZE_1280 ? 100 : 20
                      }
                    >
                      <Flex justifyContent="center">
                        <Box padding={20}>
                          <Image image={Icons.bricks} width="100" />
                        </Box>
                      </Flex>
                      <Text
                        center
                        type="uppersubtitle"
                        fontWeight={600}
                        color={COLORS.BLACK_THEME_WHITE}
                      >
                        Build You Up
                      </Text>
                      <Box padding={10}></Box>
                      <Text
                        center
                        lineHeight={1.6}
                        type="undersubtitle"
                        color={COLORS.BLACK_THEME_WHITE_GREEN}
                        fontWeight={600}
                      >
                        Caduceus helps you to perfect your pitch and fundraising strategy so that you are optimally prepared to raise capital for your fund.
                      </Text>
                    </Box>
                  </Flex>
                  <Flex alignItems="center" flexDirection="column">
                    <Box
                      backgroundColor={COLORS.BLACK_THEME_BLACK}
                      backgroundImage={COLORS.BLACK_THEME_GRADIENT_DEEP_GREEN}
                      minWidth={deviceWidth > WINDOW_SIZES.SIZE_1280 ? 400 : 0}
                      minHeight={deviceWidth > WINDOW_SIZES.SIZE_1280 ? 420 : 0}
                      borderRadius={5}
                      paddingVertical={25}
                      paddingHorizontal={
                        deviceWidth > WINDOW_SIZES.SIZE_1280 ? 100 : 20
                      }
                    >
                      <Flex justifyContent="center">
                        <Box padding={20}>
                          <Image image={Icons.signpost} width="100" />
                        </Box>
                      </Flex>
                      <Text
                        center
                        type="uppersubtitle"
                        fontWeight={600}
                        color={COLORS.BLACK_THEME_WHITE}
                      >
                        Guide You
                      </Text>
                      <Box padding={10}></Box>
                      <Text
                        center
                        lineHeight={1.6}
                        type="undersubtitle"
                        color={COLORS.BLACK_THEME_WHITE_GREEN}
                        fontWeight={600}
                      >
                       We provide ongoing guidance during your fundraising process to help you raise your fund as efficiently as possible.
                      </Text>
                    </Box>
                  </Flex>
                  <Flex alignItems="center" flexDirection="column">
                    <Box
                      backgroundColor={COLORS.BLACK_THEME_BLACK}
                      backgroundImage={COLORS.BLACK_THEME_GRADIENT_DEEP_GREEN}
                      minWidth={deviceWidth > WINDOW_SIZES.SIZE_1280 ? 400 : 0}
                      minHeight={deviceWidth > WINDOW_SIZES.SIZE_1280 ? 420 : 0}
                      borderRadius={5}
                      paddingVertical={25}
                      paddingHorizontal={
                        deviceWidth > WINDOW_SIZES.SIZE_1280 ? 100 : 20
                      }
                    >
                      <Flex justifyContent="center">
                        <Box padding={20}>
                          <Image image={Icons.people} width="100" />
                        </Box>
                      </Flex>
                      <Text
                        center
                        type="uppersubtitle"
                        fontWeight={600}
                        color={COLORS.BLACK_THEME_WHITE}
                      >
                        Connect You
                      </Text>
                      <Box padding={10}></Box>
                      <Text
                        center
                        lineHeight={1.6}
                        type="undersubtitle"
                        color={COLORS.BLACK_THEME_WHITE_GREEN}
                        fontWeight={600}
                      >
                        Outreach, introductions, appointment setting and connections to our network of investors to help you raise your round.
                      </Text>
                    </Box>
                  </Flex>
                </Flex>
                <Box padding={30}></Box>
              </Flex>
            </Box>
          </Container>
          
          <Box paddingHorizontal={20} paddingVertical={50}>
              <Flex flexDirection="column" alignItems="center" gap={20}>
                <Text center type="title" fontWeight={600}>
                  Do you need help with raising capital?
                </Text>
                <Box padding={20}>
                <Button
                    backgroundColor={COLORS.BLACK_THEME_DEEP_GREEN}
                    backgroundHoverColor={COLORS.BLACK_THEME_DIM_GREEN}
                    shadow={false}
                    onClick={() => {window.location.replace("https://calendly.com/caduceuscapital/preconsultation");}}
                  >
                    FREE PRECONSULTATION
                  </Button>
                </Box>
              </Flex>
            </Box>
        </ContentWrapper>
        <ContentWrapper
          backgroundColor={COLORS.BLACK_THEME_BLACK}
        >
          
          <Container>
            <Box padding={30}></Box>
            <Box
              paddingHorizontal={deviceWidth > WINDOW_SIZES.SIZE_1024 ? 120 : 0}//1280 ? 120 : 0}
              paddingVertical={50}
            >
              <Flex flexDirection="column" alignItems="center" gap={20}>
                <Box padding={5}>
                  <Text type="title" fontWeight={600} center>
                    FAQ
                  </Text>
                </Box>
                <Accordian contents={faq2Data} />
              </Flex>
            </Box>
            <Box padding={50}></Box>
            <Box paddingHorizontal={20} paddingVertical={50}>
              <Flex flexDirection="column" alignItems="center" gap={20}>
                <Text center type="title" fontWeight={600}>
                  Are you ready to raise your round?
                </Text>
                <Box padding={20}>
                <Button
                    backgroundColor={COLORS.BLACK_THEME_DEEP_GREEN}
                    backgroundHoverColor={COLORS.BLACK_THEME_DIM_GREEN}
                    shadow={false}
                    onClick={() => {window.location.replace("https://calendly.com/caduceuscapital/preconsultation");}}
                  >
                    FREE PRECONSULTATION
                  </Button>
                </Box>
              </Flex>
            </Box>
            <Box padding={50}></Box>
          </Container>
        </ContentWrapper>
      </Flex>
    </Page>
  );
};

const mapStateToProps = (state: any) => {
  return {
    theme: state.ThemeReducer.theme,
    requestAuditModal: state.RequestAuditModalReducer.auditModalOpened,
  };
};

export default connect(mapStateToProps, {
  changeTheme: changeTheme,
  changeAuditModalOpened: changeAuditModalOpened,
})(Investors);
