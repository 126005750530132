import React from "react";
import { Box, Container, Flex, Text, Icon } from "ui/atoms";
import { NavItem } from "ui/molecules";
import useWindowDimensions from "hooks/useWindowDimensions";
import Icons from "assets/icons";
import { COLORS } from "config/colors";
import { WINDOW_SIZES, Z_INDEX_LEVELS } from "config/dimensions";
// import { useNavigate } from "react-router-dom";

const Footer = () => {
  const { deviceWidth } = useWindowDimensions();
  // const navigate = useNavigate();
  return (
    <Box
      className="organism-footer"
      backgroundColor={COLORS.BLACK_THEME_DEEP_GREEN}
      zIndex={Z_INDEX_LEVELS.LOW}
      width="100%"
      paddingVertical={90}
    >
      <Container>
        <Flex
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="space-between"
          gap={30}
        >
          <Flex
            justifyContent="space-between"
            width="100%"
            className="organism-footer__connection-list"
          >
            <Flex flex={2} flexDirection="column">
              <Box
                onClick={() => {
                  window.location.replace("/#");
                }}
              >
                {deviceWidth > WINDOW_SIZES.SIZE_375 ? (
                  <Icon icon={Icons.caduceusLogo} size="SUPER_LARGE" />
                ) : (
                  <Icon icon={Icons.caduceusLogo} size="UPPERLARGE" />
                )}
              </Box>
              <Box maxWidth="400px" paddingVertical={20}>
                <Text
                  color={COLORS.BLUE_THEME_WHITE}
                  type="subtitle"
                  fontWeight={600}
                >
                  Web3 Fundraising Partners.
                </Text>
              </Box>
            </Flex>
            <Flex
              flex={3}
              gap={30}
              className="organism-footer__connection-list__details"
            >
              <Flex flex={1} flexDirection="column" gap={15}>
                <Box paddingVertical={10}>
                  <Text type="undersubtitle" color={COLORS.WHITE} fontWeight={600}>
                    Services
                  </Text>
                </Box>
                <NavItem fontWeight={600} 
                  onClick={() => {
                    window.location.replace("/founders-raising-funds");
                  }}>
                Founders Raising Funds
                </NavItem>
                <NavItem fontWeight={600}  
                  onClick={() => {
                    window.location.replace("/investors-raising-funds");
                  }}>
                  VCs Raising Funds
                </NavItem>
                <NavItem fontWeight={600}  
                  onClick={() => {
                    window.location.replace("/investors-deploying-capital");
                  }}>
                  VCs Deploying Capital
                </NavItem>
                <NavItem fontWeight={600}  
                  onClick={() => {
                    window.location.replace("/investors-deploying-capital");
                  }}>
                  Portfolio
                </NavItem>
              </Flex>
              <Flex flex={1} flexDirection="column" gap={15}>
                <Box
                  paddingVertical={10}
                >
                  <Text type="undersubtitle" color={COLORS.WHITE} fontWeight={600}>
                    Company
                  </Text>
                </Box>
                <NavItem fontWeight={600} onClick={() => {
                    window.location.replace("/company");
                  }}>
                  About
                </NavItem>
                {/* <NavItem>Careers</NavItem> */}
                <NavItem fontWeight={600} onClick={() => {
                    window.location.replace("/disclaimer");
                  }}>
                  Disclaimer
                </NavItem>
                <NavItem fontWeight={600} onClick={() => {
                    window.location.replace("/privacy");
                  }}>
                  Privacy Policy
                </NavItem>
                {/* <NavItem>Cookie Policy</NavItem> */}
                <NavItem fontWeight={600} onClick={() => {
                    window.location.replace("/terms");
                  }}>
                  Terms
                </NavItem>
              </Flex>
              <Flex flex={1} flexDirection="column" gap={17}>
                <Box paddingVertical={10}>
                  <Text type="undersubtitle" color={COLORS.WHITE} fontWeight={600}>
                    Socials
                  </Text>
                </Box>
                <NavItem fontWeight={600} url={"https://discordapp.com/users/1066783743425134622"}>
                      Discord
                </NavItem>
                <NavItem
                  fontWeight={600}
                  url={"https://twitter.com/ConsultCaduceus"}
                >
                      Twitter
                </NavItem>
                <NavItem
                  fontWeight={600}
                  url={"https://www.linkedin.com/company/consult-caduceus/"}
                >
                      LinkedIn
                </NavItem>
                <NavItem fontWeight={600} url={"https://medium.com/@consultcaduceus"}>
                      Medium
                </NavItem>
              </Flex>
            </Flex>
          </Flex>
          <Text color={COLORS.GRAY} type="plain" fontWeight={600}>
            © 2023 by Caduceus. All Rights Reserved.
          </Text>
        </Flex>
      </Container>
    </Box>
  );
};

export default Footer;
