export const faqData = [
  {
    title: "How do I create a compelling pitch to attract investors?",
    content: `There are many factors to consider when creating a compelling pitch. To start you should clearly articulate the problem your company is solving, the size of the market opportunity, and how your solution is uniquely positioned to capture a significant share of the market. You should also highlight your story, your team's qualifications and any notable achievements or milestones you have reached so far. Caduceus can help you to create a pitch-perfect compelling pitch deck that will ensure you attract the right investors.`,
    collapse: true
  },
  {
    title: "How do I determine how much funding I need?",
    content: `The amount of funding you need will depend on a variety of factors, including the stage of your company, the industry you are in, your projected expenses and revenue, and your growth plans. Caduceus provides guidance and helps to create a detailed financial plan to help you determine how much funding you need.`,
    collapse: true
  },
  {
    title: "How do I negotiate equity and valuations with investors?",
    content: `When negotiating equity and valuations with investors, it's important to be well-informed about the market and to have a clear idea of the value that your company brings. Caduceus can provide guidance and help to connect you with a legal or financial professional for advice.`,
    collapse: true
  },
  {
    title: "What are some alternative options for raising funds, aside from venture capital?",
    content: `Alternative options for raising funds include crowdfunding, angel investment, grants, loans, and incubators or accelerators. Caduceus provides guidance and helps to connect you with the right resources to help you determine which option is best for your company.`,
    collapse: true
  },
  {
    title:
      "How do I keep track of my progress in the fundraising process?",
    content: `Keeping track of progress in the fundraising process is very important. You can utilize spreadsheets or project management tools to organize your communications with potential investors, track the status of each opportunity, and note any next steps. Caduceus provides guidance on best practises for keeping track of your progress in the fundraising process.`,
    collapse: true
  },
  {
    title: "What legal considerations should I be aware of when raising funds?",
    content: `Some legal considerations to be aware of when raising funds include ensuring that you have completed any necessary paperwork, such as incorporation documents and securities filings, and complying with any applicable laws and regulations, such as those related to the sale of securities. It's a good idea to consult with a legal professional to ensure that you are taking the necessary steps to protect your company and comply with the law. Caduceus can connect you with legal counsel to help you navigate the legal considerations of raising funds.`,
    collapse: true
  }
];
