import React, { useEffect, useState } from "react";
import { Flex, Icon, Box, Text } from "ui/atoms";
import Icons from "assets/icons";
import { COLORS } from "config/colors";

interface IAddressLabelProps {
  shortened: boolean;
  address: string;
}

const AddressLabel: React.FC<IAddressLabelProps> = ({ shortened, address }) => {
  const [copied, setCopied] = useState<boolean>(false);

  useEffect(() => {
    if (copied) {
      const timer = setTimeout(() => {
        setCopied(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [copied]);

  return (
    <Flex gap={10} justifyContent="center" alignItems="center">
      <Box>
        {shortened
          ? address.substr(0, 4) +
            " ... " +
            address.substr(address.length - 4, 4)
          : address}
      </Box>
      <Box cursor>
        <Icon
          icon={Icons.copy}
          onClick={async () => {
            setCopied(true);
            if ("clipboard" in navigator) {
              return await navigator.clipboard.writeText(address);
            } else {
              return document.execCommand("copy", true, address);
            }
          }}
        />
      </Box>
      {
        <Box
          position="absolute"
          right={0}
          top={-10}
          backgroundColor={COLORS.BLUE_THEME_WHITE}
          padding={3}
          borderRadius={5}
          transition={"all ease-in 0.4s"}
          opacity={copied ? 1 : 0}
        >
          <Text color={COLORS.DARK_THEME_BLACK}>Copied!</Text>
        </Box>
      }
    </Flex>
  );
};

export default AddressLabel;
