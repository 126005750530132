import rocket from "./rocket.svg";
import blockchain from "./blockchain.svg";
import search from "./search.svg";
import left from "./left.svg";
import right from "./right.svg";
import discord from "./discordLogo.png";
import telegram from "./telegram.svg";
import github from "./github.svg";
import close from "./close.svg";
import menu from "./menu.svg";
import alarm from "./alarm.svg";
import dropdown from "./dropdown.svg";
import up from "./up.svg";
import proofIconWhite from "./prooficon-white.svg";
import proofIconBlack from "./prooficon-black.svg";
import proofIconBlue from "./prooficon-blue.svg";
import proofLogoWhite from "./prooflogo-white.svg";
import proofLogoBlack from "./prooflogo-black.svg";
import proofLogoBlue from "./prooflogo-blue.svg";
import star from "./star.svg";
import starBlack from "./star-black.svg";
import minus from "./minus.svg";
import plus from "./plus.svg";
import instagram from "./instagram.svg";
import linkedin from "./linkedin.svg";
import medium from "./medium.svg";
import twitter from "./twitter.svg";
import checked from "./checked.svg";
import unchecked from "./unchecked.svg";
import moonLight from "./moon-light.svg";
import moonDark from "./moon-dark.svg";
import ethereum from "./eth-white.svg";
import bnb from "./bnb-white.svg";
import polygon from "./poly-white.svg";
import tron from "./tron-white.svg";
import avalanche from "./avax-white.svg";
import checkedRound from "./checked-round.svg";
import upMini from "./up-mini.svg";
import next from "./next.svg";
import nextTeal from "./nextTeal.png";
import nextGreen from "./nextGreen.png";
import nextPurple from "./nextPurple.png";
import rectangle from "./rectangle.svg";
import closeBlack from "./closeBlack.svg";
import closeWhite from "./closeWhite.svg";
import notification from "./notification.svg";
import ethereumSvg from "./ethereum.svg";
import smartContractAudit from "./smartcontract-audit.svg";
import nftAudit from "./nft-audit.svg";
import dappAduit from "./dapp-audit.svg";
import avaxChain from "./avax-chain.svg";
import bnbChain from "./bnb-chain.svg";
import ethChain from "./eth-chain.svg";
import polygonChain from "./polygon-chain.svg";
import tronChain from "./tron-chain.svg";
import telegramSocial from "./telegram-social.svg";
import twitterSocial from "./twitter-social.svg";
import instagramSocial from "./instagram-social.svg";
import mediumSocial from "./medium-social.svg";
import githubSocial from "./github-social.svg";
import linkedinSocial from "./linkedin-social.svg";
import bellWhite from "./bell-white.svg";
import bellBlue from "./bell-blue.svg";
import bell from "./bell.svg";
import companyArt from "./company-art.svg";
import companyChess from "./company-chess.svg";
import companyDoubleCircle from "./company-doublecircle.svg";
import companyEye from "./company-eye.svg";
import companyRound from "./company-round.svg";
import companyShield from "./company-shield.svg";
import companyStar from "./company-star.svg";
import companyTarget from "./company-target.svg";
import companyUp from "./company-up.svg";
import companyZen from "./company-zen.svg";
import copy from "./copy.svg";
import pdf from "./pdf.svg";
import google from "./google.svg";
import leaderBoard from "./leaderboard.svg";
import settings from "./settings.svg";
import logOut from "./logout.svg";
import menuBar from "./menu-bars.svg";
import addNew from "./add-new.svg";
import protection from "./protection.svg";
import pinocchio from "./pinocchio.svg";
import insurance from "./insurance.svg";
import ice from "./ice.svg";
import hacker from "./hacker.svg";
import fire from "./fire.svg";
import code from "./code.svg";
import scaudit from "./scaudit.svg";
import dapp from "./dapp.svg";
import nft from "./nft.svg";
import codebook from "./codebook.svg";
import codeguard from "./codeguard.svg";
import users from "./users.svg";
import checkBlue from "./checkBlue.png";
import checkTeal from "./checkTeal.png";
import checkPurple from "./checkPurple.png";
import checkGreen from "./checkGreen.png";
import lineBlue from "./lineBlue.png";
import lineGreen from "./lineGreen.png";
import linePurple from "./linePurple.png";
import lineTeal from "./lineTeal.png";
import eth from "./eth.svg";
import binance from "./binance.svg";
import avalancheIcon from "./avalanche.svg";
import polygonIcon from "./polygon.svg";
import tronIcon from "./tron.svg";
import safe from "./safe.svg";
import flag from "./flag.svg";
import badge from "./badge.svg";
import change from "./change.svg";
import stopwatch from "./stopwatch.svg";
import utility from "./utility.svg";
import history from "./history.svg";
import bank from "./bank.svg";
import layer2 from "./layer2.svg";
import mpos from "./mpos.svg";
import utxo from "./utxo.svg";
import pyramid from "./pyramid.svg";
import ethereumlogo from "./ethereumlogo.svg";
import binancelogo from "./binancelogo.svg";
import avalanchelogo from "./avalanchelogo.svg";
import polygonlogo from "./polygonlogo.svg";
import tronlogo from "./tronlogo.svg";
import website from "./website.svg";
import logoPng from "./prooflogo-white.png";
import bellPng from "./bell-white.png";
import caduceusLogoGif from "./caduceus.gif";
import caduceusLogo from "./caduceuslogotext.png";
import idea from "./idea.svg";
import investment from "./investment.svg";
import dealing from "./dealing.svg";
import foundersraising from "./foundersraising.svg";
import investorsraising from "./investorsraising.svg";
import investorsinvesting from "./investorsinvesting.svg";
import bricks from "./bricks.svg";
import signpost from "./signpost.svg";
import people from "./people.svg";
import understand from "./understand.svg";
import present from "./present.svg";
import apollo from "./apollo.png";
import nick from "./nick.png";
import qichen from "./qichen.png";
import murph from "./murph.png";
import eddie  from "./eddie.png";
import alana from "./alana.png";
import profits from "./profits.svg";
import connection from "./connection.svg";
import fidelity from "./fidelity.svg";
import bot from "./bot.svg";
import help from "./help.svg";
import tire from "./tire.svg";
import emerald from "./emerald.svg";
import target from "./target.svg";

const Icons = {
  bot,
  help,
  tire,
  emerald,
  target,
  profits,
  connection,
  fidelity,
  apollo,
  nick,
  qichen,
  murph,
  eddie,
  alana,
  understand,
  present,
  bricks,
  signpost,
  people,
  investorsraising,
  investorsinvesting,
  foundersraising,
  caduceusLogo,
  caduceusLogoGif,
  bellPng,
  logoPng,
  rocket,
  blockchain,
  search,
  left,
  right,
  github,
  telegram,
  discord,
  close,
  menu,
  alarm,
  dropdown,
  up,
  proofIconWhite,
  proofIconBlack,
  proofIconBlue,
  proofLogoWhite,
  proofLogoBlack,
  proofLogoBlue,
  star,
  starBlack,
  minus,
  plus,
  linkedin,
  medium,
  instagram,
  twitter,
  checked,
  unchecked,
  moonLight,
  moonDark,
  ethereum,
  bnb,
  polygon,
  tron,
  avalanche,
  checkedRound,
  upMini,
  next,
  nextTeal,
  nextPurple,
  nextGreen,
  rectangle,
  closeBlack,
  closeWhite,
  notification,
  ethereumSvg,
  smartContractAudit,
  dappAduit,
  nftAudit,
  avaxChain,
  bnbChain,
  ethChain,
  polygonChain,
  tronChain,
  telegramSocial,
  twitterSocial,
  instagramSocial,
  linkedinSocial,
  mediumSocial,
  githubSocial,
  bellWhite,
  bellBlue,
  bell,
  companyArt,
  companyChess,
  companyDoubleCircle,
  companyEye,
  companyRound,
  companyShield,
  companyStar,
  companyTarget,
  companyUp,
  companyZen,
  copy,
  pdf,
  google,
  leaderBoard,
  settings,
  logOut,
  menuBar,
  addNew,
  protection,
  pinocchio,
  insurance,
  ice,
  hacker,
  fire,
  code,
  scaudit,
  codebook,
  codeguard,
  users,
  checkBlue,
  checkTeal,
  checkPurple,
  checkGreen,
  lineBlue,
  lineGreen,
  linePurple,
  lineTeal,
  dapp,
  nft,
  eth,
  binance,
  avalancheIcon,
  polygonIcon,
  tronIcon,
  safe,
  flag,
  badge,
  change,
  ethereumlogo,
  binancelogo,
  avalanchelogo,
  polygonlogo,
  tronlogo,
  stopwatch,
  bank,
  utility,
  history,
  layer2,
  mpos,
  utxo,
  pyramid,
  website,
  idea,
  dealing,
  investment,
};

export default Icons;
