export const tronFaqData = [
  {
    title: "What is Tron? What does 'Tron' mean?",
    content: `Tron is inspired by some of the design concepts of Bitcoin, the world's first decentralized cryptocurrency, but with several attempts at improvement. One of Tron's innovations is extremely low transaction fees and extremely fast transaction times.`,
    collapse: true
  },
  {
    title: "Who invented Tron? How was Tron created?",
    content: `Tron was created by Justin Sun as an attempt to technically improve Bitcoin, reducing transaction times to a few seconds instead of 10 minutes, by replacing computationally expensive proof-of-work with a proof-of-stake.`,
    collapse: true
  },
  {
    title: "What is TRON’s overall architecture?",
    content: `TRON uses a 3-layer architecture：Storage layer, Core layer and Application layer.`,
    collapse: true
  },
  {
    title: "Why is Tron good for developers?",
    content: `From a developer's standpoint, Tron is special because it's currently being built to allow the use of high-level language for its smart contracts. Developers in the future will be able to use any and every programming language to build on top of Tron's open-software design.`,
    collapse: true
  },
  {
    title:
      "How long does Tron take to transfer?",
    content: `A new block on the Tron blockchain is added every 3 seconds. The number of transactions in each block is limited to several thousand. Transactions paying higher fees are given priority over those paying lower fees.`,
    collapse: true
  },
  {
    title: "What is the transaction fee on Tron?",
    content: `The average transaction fees are a fraction of a penny. Stakeholders get free, zero fee transactions. Lots of factors affect transaction fees, such as the cost of electricity, the hardware capacity, competition between miners and the number of simultaneous transactions competing to be included in a block.`,
    collapse: true
  }
];
