export const ethFaqData = [
  {
    title: "What is Ethereum? What does 'Ethereum' mean?",
    content: `Ethereum is inspired by some of the design concepts of Bitcoin, the world's first decentralized cryptocurrency, but with several attempts at improvement. One of Ethereum's innovations is Smart Contracts, code scripts stored on the blockchain which execute when their conditions are met.`,
    collapse: true,
  },
  {
    title: "How does Ethereum work?",
    content: `Blockchain based cryptocurrencies like Ethereum work by grouping transactions together in data blocks, then linking the blocks cryptographically. The chain of blocks is stored across a decentralized network of nodes, and distributed as peer-to-peer shared files. Smart Contracts, small pieces of code, are executed on the blockchain.`,
    collapse: true,
  },
  {
    title: "What is Ethereum used for?",
    content: `Ethereum is used for anything money is used for - buying, selling, donations. But Ethereum can also be used for even more things than fiat currency: A means of accepting donations or facilitating commerce that cannot be shut down by governments or the banking system. Also storing up wealth that cannot be confiscated.`,
    collapse: true,
  },
  {
    title: "Are Ethereum transactions anonymous? Can Ethereum be traced?",
    content: `The blockchain is a public record of all transactions by all addresses. However, an address is just a number. As long as this number is never linked to an identity, the Ethereum user is safe. There is no way to "trace" a Ethereum address to the person using it - unless they leave clues connecting their Ethereum address to their physical identity.`,
    collapse: true,
  },
  {
    title:
      "What is Ethereum mining? How does Ethereum mining work?",
    content: `Ethereum mining is the computational process of adding new blocks to the blockchain. New transactions are grouped together in a block. With proof of stake, a validator is chosen randomly, based in part on how many coins they have staked in the blockchain network. The coins act as collateral and when a participant is chosen to validate a transaction, they receive a reward and the transaction is included in the block.`,
    collapse: true,
  },
  {
    title: "What are Ethereum smart contracts? How do Ethereum smart contracts work?",
    content: `Smart Contracts are code scripts stored on the blockchain which execute when their conditions are met. Ethereum Smart Contracts are Turing Complete, which means they can perform all of the functions of a computer language, and even have their own programming language called Solidity.`,
    collapse: true,
  },
];
