import React from "react";
import { Box, Text, Flex, Icon } from "ui/atoms";
import { COLORS } from "config/colors";
import Icons from "assets/icons";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

interface ITextBoxProps {
  title: string;
  content: string;
  titleColor?: string;
  backgroundColor?: string;
  backgroundImage?: string;
  icon?: boolean;
  link?: string;
  minHeight?: string | number;
  minWidth?: string | number;
  hoverBorder?: boolean;
}

const TextBoxGreen: React.FC<ITextBoxProps> = ({
  title,
  content,
  titleColor = COLORS.DARK_THEME_BLACK,
  backgroundColor = COLORS.BLACK_THEME_BLACK,
  backgroundImage = "linear-gradient(to left, #586F02, #7B9B02)",
  icon = false,
  link = "",
  minHeight = "auto",
  minWidth = "auto",
  hoverBorder = false,
}) => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  return (
    <Box
      borderWidth={0}
      borderColor={COLORS.GREY}
      backgroundColor={COLORS.DARK_THEME_TRANSPARENT}
      backgroundHoverBorderColor={hoverBorder ? "green" : "transparent"}
      borderRadius={10}
      width="100%"
      cursor
      onMouseEnter={() => setIsHovered(!isHovered)}
      onMouseLeave={() => setIsHovered(!isHovered)}
      onClick={() => {
        if (icon) {
          navigate("/" + link);
        }
      }}
    >
      <Flex flexDirection="column" height="100%">
        <Box
          backgroundColor={backgroundColor}
          backgroundImage={backgroundImage}
          borderRadius={10}
          padding={30}
          borderColor={COLORS.GREY}
          borderWidth={0}
          height="100%"
          minHeight={minHeight}
          minWidth={minWidth}
        >
          <Text type="uppersubtitle" fontFamily="GilroyBold" color={titleColor}>
            {title}
          </Text>
          <Box padding={10}></Box>
          <Text
            lineHeight={1.6}
            type="undersubtitle"
            color={COLORS.BLACK_THEME_WHITE_GREEN}
            fontFamily="GilroyBold"
          >
            {content}
          </Text>
          {icon && <Box padding={15} backgroundColor="transparent"></Box>}
          {icon && (
            <Box cursor>
              <Icon
                icon={isHovered ? Icons.nextGreen : Icons.next}
                size="LARGE"
                onClick={() => {
                  navigate("/" + link);
                }}
              />
            </Box>
          )}
        </Box>
      </Flex>
    </Box>
  );
};

export default TextBoxGreen;
