import React, { useState, useEffect } from "react";
import {
  Flex,
  Box,
  Text,
  Grid,
  GridItem,
  Icon,
  Select,
  Downloader,
} from "ui/atoms";
import {
  Checker,
  ProgressSpinner,
  AddressLabel,
  SearchInput,
  Paginator,
  SearchDropDown,
} from "ui/molecules";
import { COLORS } from "config/colors";
import useWindowDimensions from "hooks/useWindowDimensions";
import { WINDOW_SIZES, Z_INDEX_LEVELS } from "config/dimensions";
import Icons from "assets/icons";
import { Tokens } from "assets/tokens";
// import { tableData } from "assets/json/tableData";
import axios from "axios";
import { SERVER_URL } from "config/api";
import Spin from "ui/atoms/spin";
import { connect } from "react-redux";
import { changeTheme } from "redux/actions/theme";
import { changeAuditModalOpened } from "redux/actions/requestAuditModal";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
interface IDataProps {
  title: string;
  checked?: boolean;
}

interface ILinkProps {
  type: string;
  url: string;
}

interface IChainProps {
  title: string;
  checked: boolean;
}

interface ITableItemProps {
  _id: string;
  is_star: boolean;
  name: string;
  icon: string;
  trust_score: number;
  links: Array<ILinkProps>;
  contract: string;
  chain: Array<IChainProps>;
  category: string;
  audit_pdf: string;
  date: string;
}

const Table = (props: any) => {
  const { deviceWidth } = useWindowDimensions();
  const [pageRowCount, setPageRowCount] = useState<number>(10);
  const [filter, setFilter] = useState<string>("");
  const [categoryFilter, setCategoryFilter] = useState<Array<IDataProps>>([]);
  const [chainFilter, setChainFilter] = useState<Array<IDataProps>>([]);
  const [currentPageIndex, setCurrentPageIndex] = useState<number>(1);
  const [tableData, setTableData] = useState<Array<ITableItemProps>>([]);
  const [tableStatus, setTableStatus] = useState<"initial" | "loaded">(
    "initial"
  );

  const determineChain = (chainName: string) => {
    switch (chainName) {
      case "Avalanche":
        return Tokens.avax;
      case "Avax":
        return Tokens.avax;
      case "Bnb":
        return Tokens.bnb;
      case "Binance":
        return Tokens.bnb;
      case "Eth":
        return Tokens.eth;
      case "Ethereum":
        return Tokens.eth;
      case "Tron":
        return Icons.tronIcon;
      case "Polygon":
        return Icons.polygonIcon;
      default:
        return Tokens.eth;
    }
  };

  const determineLinks = (linkName: string) => {
    switch (linkName) {
      case "website":
        return Icons.website;
      case "twitter":
        return Icons.twitter;
      case "discord":
        return Icons.discord;
      case "telegram":
        return Icons.telegram;
      default:
        return Icons.telegram;
    }
  };

  const getTableData = () => {
    return axios
      .get(SERVER_URL + "/table-data", {
        params: {},
      })
      .then((res) => {
        setTableData(res.data);
        console.log(">>>>", res.data);
        setTableStatus("loaded");
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getTableData();
  }, [props.tokenAddModalOpened]);

  setTimeout(() => {
    if (tableStatus === "initial") {
      setTableStatus("loaded");
    }
  }, 20000); // 20s

  const checkCategoryFilter = (tableItem: ITableItemProps) => {
    let result = false;
    if (categoryFilter.length === 0) result = true; // means emtpy filter = all
    categoryFilter.forEach((categoryFilterItem) => {
      if (
        tableItem.category
          .toLowerCase()
          .includes(categoryFilterItem.title.toLowerCase())
      )
        result = true;
    });
    return result;
  };

  const checkChainFitler = (tableItem: ITableItemProps) => {
    let result = false;
    if (chainFilter.length === 0) result = true; // means empty filter = all
    chainFilter.forEach((chainFilterItem) => {
      tableItem.chain.forEach((chainItem) => {
        if (
          chainItem.title
            .toLowerCase()
            .includes(chainFilterItem.title.toLowerCase())
        )
          result = true;
      });
    });
    return result;
  };

  const filterSatisfy = (tableItem: ITableItemProps) => {
    // Checking chain filter first
    if (!checkChainFitler(tableItem)) return false;

    // Checking category filter second
    if (!checkCategoryFilter(tableItem)) return false;

    if (tableItem.name.toLowerCase().includes(filter.toLowerCase()))
      return true;
    if (tableItem.contract.toLowerCase().includes(filter.toLowerCase()))
      return true;
    if (tableItem.category.toLowerCase().includes(filter.toLowerCase()))
      return true;
    if (tableItem.date.toLowerCase().includes(filter.toLowerCase()))
      return true;
    return false;
  };

  console.log(Tokens);

  return (
    <Flex flexDirection="column">
      <Box position="relative" paddingVertical={30} paddingHorizontal={30}>
        <Flex
          flexDirection={deviceWidth < WINDOW_SIZES.SIZE_874 ? "column" : "row"}
          alignItems={
            deviceWidth < WINDOW_SIZES.SIZE_874 ? "initial" : "center"
          }
        >
          <SearchInput
            color={COLORS.BLUE_THEME_BORDER}
            getter={setFilter}
            size="small"
            placeholder="Search Project by Name/Token"
          />
          <Flex>
            <Box
              position="relative"
              left={deviceWidth < WINDOW_SIZES.SIZE_874 ? 0 : 20}
              top={deviceWidth < WINDOW_SIZES.SIZE_874 ? 30 : 0}
              height={deviceWidth < WINDOW_SIZES.SIZE_874 ? 40 : 0}
            >
              <SearchDropDown
                getter={setChainFilter}
                border
                top={-22}
                fixedSubMenu={deviceWidth < WINDOW_SIZES.SIZE_874}
                zIndexLimit={Z_INDEX_LEVELS.MAXIMUM}
                data={[
                  { title: "Ethereum", checked: false },
                  { title: "Avalanche", checked: false },
                  { title: "Binance", checked: false },
                  { title: "Tron", checked: false },
                  { title: "Polygon", checked: false },
                ]}
                title="Chains"
                direction="left"
              />
            </Box>
            <Box
              position="relative"
              left={140}
              top={deviceWidth < WINDOW_SIZES.SIZE_874 ? 30 : 0}
            >
              <SearchDropDown
                getter={setCategoryFilter}
                border
                fixedSubMenu={deviceWidth < WINDOW_SIZES.SIZE_874}
                top={-22}
                zIndexLimit={Z_INDEX_LEVELS.MAXIMUM}
                data={[
                  { title: "DeFi", checked: false },
                  { title: "DEX", checked: false },
                  { title: "CEX", checked: false },
                  { title: "Gaming", checked: false },
                  { title: "NFT", checked: false },
                  { title: "Metaverse", checked: false },
                  { title: "DAO", checked: false },
                  { title: "Web3", checked: false },
                  { title: "Launchpad", checked: false },
                  { title: "Marketplace", checked: false },
                ]}
                title="Category"
                direction="left"
              />
            </Box>
          </Flex>
        </Flex>
      </Box>
      <Box padding={10}></Box>
      <Box
        backgroundColor={COLORS.BLUE_THEME_DARK_BLUE}
        borderColor={COLORS.BLUE_THEME_DARK_BLUE}
        borderWidth={1}
        padding={5}
        borderRadius={20}
      >
        <Box backgroundColor={COLORS.BLUE_THEME_DARK_BLUE}>
          <Flex flexDirection="column">
            <Flex
              gap={0}
              justifyContent={
                deviceWidth > WINDOW_SIZES.SIZE_768 ? "space-between" : "center"
              }
            >
              <Box
                width={
                  deviceWidth > WINDOW_SIZES.SIZE_375
                    ? deviceWidth > WINDOW_SIZES.SIZE_1024
                      ? "21%"
                      : "60%"
                    : "70%"
                }
              >
                <Box backgroundColor={COLORS.BLUE_THEME_DARK_BLUE}>
                  <Box
                    backgroundColor={COLORS.BLUE_THEME_DARK_BLUE}
                    paddingVertical={5}
                  >
                    <Grid alignCenter gap={0}>
                      <GridItem columns={2} minWidth={40}>
                        <Box
                          backgroundColor={COLORS.BLUE_THEME_DARK_BLUE}
                          padding={10}
                        >
                          <Text
                            noWrap
                            color={COLORS.DARK_THEME_WHITE}
                            type="plain"
                            fontWeight={600}
                          >
                            {}
                          </Text>
                        </Box>
                      </GridItem>
                      <GridItem
                        columns={deviceWidth > WINDOW_SIZES.SIZE_1280 ? 3 : 2}
                        minWidth={40}
                      >
                        <Box
                          backgroundColor={COLORS.BLUE_THEME_DARK_BLUE}
                          padding={10}
                        >
                          <Text
                            noWrap
                            color={COLORS.DARK_THEME_WHITE}
                            type="plain"
                            fontWeight={600}
                          >
                            #
                          </Text>
                        </Box>
                      </GridItem>
                      <GridItem
                        columns={deviceWidth >= WINDOW_SIZES.SIZE_1280 ? 7 : 8}
                      >
                        <Box
                          backgroundColor={COLORS.BLUE_THEME_DARK_BLUE}
                          height="100%"
                          paddingHorizontal={20}
                        >
                          <Text
                            noWrap
                            color={COLORS.DARK_THEME_WHITE}
                            type="plain"
                            fontWeight={600}
                          >
                            Name
                          </Text>
                        </Box>
                      </GridItem>
                    </Grid>
                  </Box>
                  <Box paddingVertical={1.5} />
                  <Box
                    paddingVertical={1}
                    backgroundColor={COLORS.BLUE_THEME_BORDER}
                  />
                </Box>
                <Flex flexDirection="column" gap={3}>
                  {/* The first token name part data is here */}
                  <Box
                    backgroundColor={COLORS.BLUE_THEME_DARK_BLUE}
                    paddingVertical={0}
                  >
                    <Grid alignCenter gap="0px 0px">
                      {tableData
                        .filter((tableDataItem) => {
                          return filterSatisfy(tableDataItem);
                        })
                        .map((tableItem, index) => {
                          return (
                            index >= (currentPageIndex - 1) * pageRowCount &&
                            index < currentPageIndex * pageRowCount && [
                              <GridItem key={index} columns={2} minWidth={30}>
                                <Box
                                  backgroundColor={
                                    index % 2 === 0
                                      ? COLORS.BLUE_THEME_TABLE_EVEN
                                      : COLORS.BLUE_THEME_TABLE_ODD
                                  }
                                  minHeight={70}
                                  maxHeight={70}
                                  paddingVertical={27}
                                  paddingHorizontal={15}
                                  borderColor={COLORS.BLUE_THEME_BORDER}
                                  borderBottomWidth={0}
                                >
                                  <Flex justifyContent="center">
                                  <Checker
                                      _id={tableItem._id}
                                      stateChangable={false}
                                      defaultCheckState={tableItem.is_star}
                                      starCountGetter={() => {}}
                                      setter={() => {}}
                                    />
                                  </Flex>
                                </Box>
                              </GridItem>,
                              <GridItem
                                key={index}
                                columns={
                                  deviceWidth > WINDOW_SIZES.SIZE_1280 ? 3 : 2
                                }
                                minWidth={30}
                              >
                                <Box
                                  backgroundColor={
                                    index % 2 === 0
                                      ? COLORS.BLUE_THEME_TABLE_EVEN
                                      : COLORS.BLUE_THEME_TABLE_ODD
                                  }
                                  minHeight={70}
                                  maxHeight={70}
                                  paddingVertical={27}
                                  paddingHorizontal={10}
                                  borderColor={COLORS.BLUE_THEME_BORDER}
                                  borderBottomWidth={0}
                                >
                                  <Flex justifyContent="flex-start">
                                    <Text noWrap>{index + 1}</Text>
                                  </Flex>
                                </Box>
                              </GridItem>,
                              <GridItem
                                key={index + 1}
                                minWidth={30}
                                columns={
                                  deviceWidth > WINDOW_SIZES.SIZE_1280 ? 7 : 8
                                }
                              >
                                <Box
                                  backgroundColor={
                                    index % 2 === 0
                                      ? COLORS.BLUE_THEME_TABLE_EVEN
                                      : COLORS.BLUE_THEME_TABLE_ODD
                                  }
                                  height="100%"
                                  minHeight={70}
                                  maxHeight={70}
                                  paddingVertical={27}
                                  paddingHorizontal={10}
                                  borderColor={COLORS.BLUE_THEME_BORDER}
                                  borderBottomWidth={0}
                                >
                                  <Flex
                                    alignItems="center"
                                    justifyContent="flex-start"
                                    gap={10}
                                  >
                                    <Icon icon={tableItem.icon} />
                                    <Text noWrap>{tableItem.name}</Text>
                                  </Flex>
                                </Box>
                              </GridItem>,
                            ]
                          );
                        })}
                    </Grid>
                  </Box>
                  <Box backgroundColor={COLORS.BLUE_THEME_DARK_BLUE} />
                </Flex>
              </Box>
              <Box
                overflowX={
                  deviceWidth > WINDOW_SIZES.SIZE_1024 ? "hidden" : "scroll"
                }
                width={
                  deviceWidth > WINDOW_SIZES.SIZE_375
                    ? deviceWidth > WINDOW_SIZES.SIZE_1024
                      ? "79%"
                      : "40%"
                    : "30%"
                }
              >
                <Box backgroundColor={COLORS.BLUE_THEME_DARK_BLUE}>
                  <Box
                    backgroundColor={COLORS.BLUE_THEME_DARK_BLUE}
                    paddingVertical={0}
                  >
                    <Box height={0} />
                    <Grid alignCenter gap={0}>
                      <GridItem columns={2} minWidth={100}>
                        <Box
                          backgroundColor={COLORS.BLUE_THEME_DARK_BLUE}
                          padding={15}
                          borderBottomWidth={2}
                          borderColor={COLORS.BLUE_THEME_BORDER}
                          minWidth={100}
                        >
                          <Text
                            noWrap
                            center
                            color={COLORS.DARK_THEME_WHITE}
                            type="plain"
                            fontWeight={600}
                          >
                            Trust Score
                          </Text>
                          <Box height={3} />
                        </Box>
                      </GridItem>
                      <GridItem columns={2} minWidth={140}>
                        <Box
                          backgroundColor={COLORS.BLUE_THEME_DARK_BLUE}
                          padding={15}
                          borderBottomWidth={2}
                          borderColor={COLORS.BLUE_THEME_BORDER}
                        >
                          <Text
                            noWrap
                            center
                            color={COLORS.DARK_THEME_WHITE}
                            type="plain"
                            fontWeight={600}
                          >
                            Links
                          </Text>
                          <Box height={3} />
                        </Box>
                      </GridItem>
                      <GridItem columns={3} minWidth={160}>
                        <Box
                          backgroundColor={COLORS.BLUE_THEME_DARK_BLUE}
                          padding={15}
                          borderBottomWidth={2}
                          borderColor={COLORS.BLUE_THEME_BORDER}
                        >
                          <Text
                            noWrap
                            center
                            color={COLORS.DARK_THEME_WHITE}
                            type="plain"
                            fontWeight={600}
                          >
                            Contract
                          </Text>
                          <Box height={3} />
                        </Box>
                      </GridItem>
                      <GridItem columns={2} minWidth={80}>
                        <Box
                          backgroundColor={COLORS.BLUE_THEME_DARK_BLUE}
                          padding={15}
                          borderBottomWidth={2}
                          borderColor={COLORS.BLUE_THEME_BORDER}
                        >
                          <Text
                            noWrap
                            center
                            color={COLORS.DARK_THEME_WHITE}
                            type="plain"
                            fontWeight={600}
                          >
                            Chain
                          </Text>
                          <Box height={3} />
                        </Box>
                      </GridItem>
                      <GridItem columns={1} minWidth={100}>
                        <Box
                          backgroundColor={COLORS.BLUE_THEME_DARK_BLUE}
                          padding={15}
                          borderBottomWidth={2}
                          borderColor={COLORS.BLUE_THEME_BORDER}
                        >
                          <Text
                            noWrap
                            center
                            color={COLORS.DARK_THEME_WHITE}
                            type="plain"
                            fontWeight={600}
                          >
                            Category
                          </Text>
                          <Box height={3} />
                        </Box>
                      </GridItem>
                      <GridItem columns={1}>
                        <Box
                          backgroundColor={COLORS.BLUE_THEME_DARK_BLUE}
                          padding={15}
                          borderBottomWidth={2}
                          borderColor={COLORS.BLUE_THEME_BORDER}
                        >
                          <Text
                            noWrap
                            center
                            color={COLORS.DARK_THEME_WHITE}
                            type="plain"
                            fontWeight={600}
                          >
                            Audit
                          </Text>
                          <Box height={3} />
                        </Box>
                      </GridItem>
                      <GridItem columns={1} minWidth={120}>
                        <Box
                          backgroundColor={COLORS.BLUE_THEME_DARK_BLUE}
                          padding={15}
                          borderBottomWidth={2}
                          borderColor={COLORS.BLUE_THEME_BORDER}
                        >
                          <Text
                            noWrap
                            center
                            color={COLORS.DARK_THEME_WHITE}
                            type="plain"
                            fontWeight={600}
                          >
                            Date
                          </Text>
                          <Box height={3} />
                        </Box>
                      </GridItem>
                    </Grid>
                    {/* <Box paddingVertical={4} /> */}
                    {/* <Box
                      paddingVertical={1}
                      backgroundColor={COLORS.BLUE_THEME_BORDER}
                    /> */}
                  </Box>
                </Box>
                <Flex flexDirection="column" gap={3}>
                  {/* The contract infor part is here */}
                  <Box
                    backgroundColor={COLORS.BLUE_THEME_IRON_BLUE}
                    // paddingVertical={4}
                  >
                    <Grid alignCenter gap={"0px 0px"}>
                      {tableData
                        .filter((tableDataItem) => {
                          return filterSatisfy(tableDataItem);
                        })
                        .map((item, index) => {
                          return (
                            index >= (currentPageIndex - 1) * pageRowCount &&
                            index < currentPageIndex * pageRowCount && [
                              <GridItem key={index} columns={2} minWidth={100}>
                                <Box
                                  backgroundColor={
                                    index % 2 === 0
                                      ? COLORS.BLUE_THEME_TABLE_EVEN
                                      : COLORS.BLUE_THEME_TABLE_ODD
                                  }
                                  minHeight={70}
                                  maxHeight={70}
                                  paddingVertical={15}
                                  borderColor={COLORS.BLUE_THEME_BORDER}
                                  borderBottomWidth={0}
                                >
                                  <Flex justifyContent="center">
                                    <ProgressSpinner
                                      progress={item.trust_score}
                                      indicatorColor={COLORS.GREEN}
                                    />
                                  </Flex>
                                </Box>
                              </GridItem>,
                              <GridItem
                                key={index + 1}
                                columns={2}
                                minWidth={140}
                              >
                                <Box
                                  backgroundColor={
                                    index % 2 === 0
                                      ? COLORS.BLUE_THEME_TABLE_EVEN
                                      : COLORS.BLUE_THEME_TABLE_ODD
                                  }
                                  minHeight={70}
                                  maxHeight={70}
                                  paddingVertical={16}
                                  borderColor={COLORS.BLUE_THEME_BORDER}
                                  borderBottomWidth={0}
                                  position="relative"
                                >
                                  <Flex
                                    height={"100%"}
                                    justifyContent="center"
                                    alignItems="center"
                                  >
                                    <Box
                                      borderColor={COLORS.BLUE_THEME_CHALK_BLUE}
                                      borderWidth={1}
                                      borderRadius={100}
                                      paddingVertical={10}
                                      paddingHorizontal={25}
                                      width="fit-content"
                                      minHeight={38}
                                      minWidth={130}
                                      backgroundColor={
                                        COLORS.BLUE_THEME_TABLE_ODD
                                      }
                                    >
                                      <Flex gap={5} justifyContent="center">
                                        {item.links.map((linkItem, index) => {
                                          return (
                                            <Box
                                              onClick={() => {
                                                window.location.replace(
                                                  linkItem.url
                                                );
                                              }}
                                            >
                                              <Icon
                                                icon={determineLinks(
                                                  linkItem.type
                                                )}
                                                key={index}
                                              />
                                            </Box>
                                          );
                                        })}
                                      </Flex>
                                    </Box>
                                  </Flex>
                                </Box>
                              </GridItem>,
                              <GridItem
                                key={index + 2}
                                columns={3}
                                minWidth={160}
                              >
                                <Box
                                  backgroundColor={
                                    index % 2 === 0
                                      ? COLORS.BLUE_THEME_TABLE_EVEN
                                      : COLORS.BLUE_THEME_TABLE_ODD
                                  }
                                  minHeight={70}
                                  maxHeight={70}
                                  paddingVertical={27}
                                  borderColor={COLORS.BLUE_THEME_BORDER}
                                  borderBottomWidth={0}
                                >
                                  <Text noWrap center>
                                    <AddressLabel
                                      address={item.contract.toLowerCase()}
                                      shortened={
                                        deviceWidth < WINDOW_SIZES.SIZE_3000
                                      }
                                    />
                                  </Text>
                                </Box>
                              </GridItem>,
                              <GridItem
                                key={index + 3}
                                columns={2}
                                minWidth={80}
                              >
                                <Box
                                  backgroundColor={
                                    index % 2 === 0
                                      ? COLORS.BLUE_THEME_TABLE_EVEN
                                      : COLORS.BLUE_THEME_TABLE_ODD
                                  }
                                  minHeight={70}
                                  maxHeight={70}
                                  paddingVertical={25}
                                  borderColor={COLORS.BLUE_THEME_BORDER}
                                  borderBottomWidth={0}
                                >
                                  <Flex gap={0} justifyContent="center">
                                    {item.chain.map((chainItem, index) => {
                                      return (
                                        <Box marginLeft={-4}>
                                          <Icon
                                            icon={determineChain(
                                              chainItem.title
                                            )}
                                            size="UPPERSMALL"
                                            key={index}
                                          />
                                        </Box>
                                      );
                                    })}
                                  </Flex>
                                </Box>
                              </GridItem>,
                              <GridItem
                                key={index + 4}
                                columns={1}
                                minWidth={100}
                              >
                                <Box
                                  backgroundColor={
                                    index % 2 === 0
                                      ? COLORS.BLUE_THEME_TABLE_EVEN
                                      : COLORS.BLUE_THEME_TABLE_ODD
                                  }
                                  minHeight={70}
                                  maxHeight={70}
                                  paddingVertical={27}
                                  borderColor={COLORS.BLUE_THEME_BORDER}
                                  borderBottomWidth={0}
                                >
                                  <Text noWrap center>
                                    {item.category}
                                  </Text>
                                </Box>
                              </GridItem>,
                              <GridItem key={index + 5} columns={1}>
                                <Box
                                  backgroundColor={
                                    index % 2 === 0
                                      ? COLORS.BLUE_THEME_TABLE_EVEN
                                      : COLORS.BLUE_THEME_TABLE_ODD
                                  }
                                  minHeight={70}
                                  maxHeight={70}
                                  paddingVertical={23}
                                  borderColor={COLORS.BLUE_THEME_BORDER}
                                  borderBottomWidth={0}
                                >
                                  <Flex
                                    justifyContent="center"
                                    gap={5}
                                    alignItems="center"
                                  >
                                    <Downloader url={item.audit_pdf} />
                                  </Flex>
                                </Box>
                              </GridItem>,
                              <GridItem
                                key={index + 6}
                                columns={1}
                                minWidth={120}
                              >
                                <Box
                                  backgroundColor={
                                    index % 2 === 0
                                      ? COLORS.BLUE_THEME_TABLE_EVEN
                                      : COLORS.BLUE_THEME_TABLE_ODD
                                  }
                                  minHeight={70}
                                  maxHeight={70}
                                  paddingVertical={27}
                                  borderColor={COLORS.BLUE_THEME_BORDER}
                                  borderBottomWidth={0}
                                >
                                  <Text
                                    noWrap
                                    type="plain"
                                    color={COLORS.DARK_THEME_WHITE}
                                    center
                                  >
                                    {new Date(item.date)
                                      .toLocaleDateString("en-GB")
                                      .split("/")
                                      .reverse()
                                      .join("-")}
                                  </Text>
                                </Box>
                              </GridItem>,
                            ]
                          );
                        })}
                    </Grid>
                  </Box>
                  <Box
                    backgroundColor={COLORS.BLUE_THEME_DARK_BLUE}
                    padding={10}
                  />
                </Flex>
              </Box>
            </Flex>
            {tableStatus === "loaded" && tableData.length === 0 && (
              <Text center color={COLORS.WHITE}>
                No Data
              </Text>
            )}
            {tableStatus === "initial" && <Spin />}
            <Box padding={10} />
            <Flex
              flexDirection={
                deviceWidth > WINDOW_SIZES.SIZE_768 ? "row" : "column"
              }
              justifyContent="center"
              alignItems="center"
              gap={10}
            >
              <Paginator
                getter={setCurrentPageIndex}
                pagesCount={Math.ceil(
                  tableData.filter((tableDataItem) => {
                    return filterSatisfy(tableDataItem);
                  }).length / pageRowCount
                )}
                currentPageIndex={currentPageIndex}
              />
              <Flex alignItems="center" gap={10}>
                <Text>Show Rows</Text>
                <Select
                  getter={setPageRowCount}
                  options={[5, 10, 20, 50, 100]}
                />
              </Flex>
            </Flex>
          </Flex>
        </Box>
      </Box>
      <Box padding={70}></Box>
      <ToastContainer />
    </Flex>
  );
};

const mapStateToProps = (state: any) => {
  return {
    theme: state.ThemeReducer.theme,
    requestAuditModal: state.RequestAuditModalReducer.auditModalOpened,
  };
};

export default connect(mapStateToProps, {
  changeTheme: changeTheme,
  changeAuditModalOpened: changeAuditModalOpened,
})(Table);
