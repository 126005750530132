import { Icons } from "assets";
import React from "react";
import { Icon } from "ui/atoms";

interface IDownloaderProps {
    url: string;
};

const Downloader:React.FC<IDownloaderProps> = ({
    url
}) => {
    return <a href={url} download="AuditPDF">
        <Icon icon={Icons.pdf} size="MEDIUM" />
    </a>
};

export default Downloader;