import avax from "./avax.svg";
import bnb from "./bnb.svg";
import eth from "./eth.svg";
import shinoInu from "./shino-inu.svg";

const Tokens = {
  avax,
  bnb,
  eth,
  shinoInu,
};

export { Tokens };
