import React, { useEffect, useState } from "react";
import { Box, Flex, Text, Grid, GridItem, Icon } from "ui/atoms";
import { Button, CheckBox } from "ui/molecules";
import { COLORS } from "config/colors";
import Icons from "assets/icons";
import useWindowDimensions from "hooks/useWindowDimensions";
import { Z_INDEX_LEVELS, WINDOW_SIZES } from "config/dimensions";

interface IDataProps {
  title: string;
  checked?: boolean;
}

interface IDropDownProps {
  data?: Array<IDataProps>;
  direction?: "left" | "right";
  top?: number | "auto";
  bottom?: number | "auto";
  border?: boolean;
  title?: string;
  menuWidth?: number | string;
  buttonWidth?: number | "100%";
  fixedSubMenu?: boolean;
  borderRadius?: number;
  backgroundColor?: string;
  zIndexLimit?: number;
  maxHeight?: number | "auto";
  singleSelect?: boolean;
  getter: Function;
}

const SearchDropDown: React.FC<IDropDownProps> = ({
  data = [],
  direction = "left",
  top = -12,
  bottom = "auto",
  border = false,
  title = "Category",
  menuWidth = "auto",
  buttonWidth = 100,
  fixedSubMenu = false,
  borderRadius = 8,
  backgroundColor = COLORS.BLUE_THEME_DARK_BLUE,
  zIndexLimit = Z_INDEX_LEVELS.MAXIMUM,
  maxHeight = "auto",
  singleSelect = false,
  getter,
}) => {
  const { deviceWidth } = useWindowDimensions();
  const [dataSet, setDataSet] = useState<Array<IDataProps>>(data);
  const [filter] = useState<string>("");
  const [collapsed, setCollapsed] = useState<boolean>(true);

  useEffect(() => {
    if (dataSet.length > 0) {
      const filteredData = dataSet.filter((dataSetItem) => {
        return dataSetItem.checked;
      });
      getter(
        singleSelect
          ? filteredData.length > 0
            ? filteredData[0].title
            : ""
          : filteredData
      );
    } else {
      console.log("Search Dropdown component initializing now");
    }
  }, [dataSet, getter, singleSelect]);

  return (
    <Box
      position="absolute"
      left={direction === "left" ? 0 : "auto"}
      top={top}
      bottom={bottom}
      right={direction === "right" ? 0 : "auto"}
      paddingVertical={1}
      maxHeight={maxHeight}
      width="100%"
    >
      <Flex
        flexDirection={top === "auto" ? "column-reverse" : "column"}
        width={deviceWidth >= WINDOW_SIZES.SIZE_768 ? 260 : menuWidth}
      >
        <Box
          paddingVertical={13}
          paddingHorizontal={12}
          borderColor={COLORS.BLUE_THEME_BORDER}
          borderWidth={border ? 1 : 0}
          borderRadius={borderRadius}
          backgroundColor={backgroundColor}
          cursor
          width={buttonWidth}
          onClick={() => {
            setCollapsed(!collapsed);
          }}
          onMouseEnter={() => {
            if (!fixedSubMenu) {
              setCollapsed(false);
            }
          }}
          onMouseLeave={() => {
            if (!fixedSubMenu) {
              setCollapsed(true);
            }
          }}
        >
          <Flex gap={menuWidth >= 300 ? 15 : 5} justifyContent="space-between">
            <Box>
              <Text
                type="plain"
                fontWeight={600}
                color={COLORS.DARK_THEME_WHITE}
              >
                {title}
              </Text>
            </Box>
            <Icon icon={Icons.dropdown} />
          </Flex>
        </Box>
        <Box
          paddingVertical={5}
          onMouseEnter={() => {
            setCollapsed(false);
          }}
          onMouseLeave={() => {
            setCollapsed(true);
          }}
        />
        {!collapsed && (
          <Box
            zIndex={zIndexLimit + 1}
            position={fixedSubMenu ? "fixed" : "initial"}
            top={fixedSubMenu ? 100 : "auto"}
            left={
              fixedSubMenu
                ? deviceWidth >= WINDOW_SIZES.SIZE_768
                  ? "10%"
                  : "5%"
                : "auto"
            }
            width={
              fixedSubMenu
                ? deviceWidth >= WINDOW_SIZES.SIZE_768
                  ? "80%"
                  : "90%"
                : "auto"
            }
            paddingVertical={10}
            paddingHorizontal={20}
            marginTop={-5}
            backgroundColor={backgroundColor}
            borderRadius={15}
            borderColor={COLORS.BLUE_THEME_BORDER}
            borderWidth={1}
            onMouseEnter={() => {
              setCollapsed(false);
            }}
            onMouseLeave={() => {
              setCollapsed(true);
            }}
          >
            {/* <SearchInput
              size="small"
              placeholder="Input category here"
              width={"100%"}
              getter={setFilter}
            /> */}
            <Box>
              {/* <Box paddingVertical={5} /> */}
              <Box paddingVertical={15} paddingHorizontal={10}>
                <Text
                  color={COLORS.DARK_THEME_WHITE}
                  type="paragraph"
                  fontWeight={600}
                >
                  {title}
                </Text>
              </Box>
              <Box overflowY="scroll" maxHeight={300} paddingHorizontal={10}>
                <Grid gap={13}>
                  {dataSet.map((dataItem, index) => {
                    return (
                      dataItem.title
                        .toLowerCase()
                        .includes(filter.toLowerCase()) && (
                        <GridItem columns={12} key={index}>
                          <Box
                            onClick={() => {
                              if (singleSelect) {
                                setDataSet((current) =>
                                  current.map((item) => {
                                    if (item.title === dataItem.title)
                                      return {
                                        ...item,
                                        checked: true,
                                      };
                                    else
                                      return {
                                        ...item,
                                        checked: false,
                                      };
                                  })
                                );
                              } else {
                                setDataSet((current) =>
                                  current.map((item) => {
                                    if (item.title === dataItem.title)
                                      return {
                                        ...item,
                                        checked: !item.checked,
                                      };
                                    else return item;
                                  })
                                );
                              }
                            }}
                          >
                            <Flex
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Flex gap={5} alignItems="center">
                                <CheckBox
                                  checkedIcon={Icons.checked}
                                  unCheckedIcon={Icons.unchecked}
                                  checked={dataItem.checked}
                                />
                                <Text
                                  type="plain"
                                  color={COLORS.BLUE_THEME_GREY}
                                >
                                  {dataItem.title}
                                </Text>
                              </Flex>
                              {/* <Box
                                paddingHorizontal={6}
                                paddingVertical={2}
                                backgroundColor={COLORS.BLUE_THEME_DARK_GREY}
                                borderRadius={5}
                              >
                                <Text
                                  type="plain"
                                  color={COLORS.DARK_THEME_WHITE}
                                >
                                  {dataItem.amount}
                                </Text>
                              </Box> */}
                            </Flex>
                          </Box>
                        </GridItem>
                      )
                    );
                  })}
                </Grid>
              </Box>
              <Box>
                <Box
                  paddingVertical={5}
                  borderBottomWidth={1}
                  borderColor={COLORS.GRAY}
                />
                <Box paddingVertical={5} />
                <Button
                  backgroundColor={COLORS.BLUE_THEME_DARK_GREY}
                  backgroundHoverColor={COLORS.BLUE_THEME_GREY}
                  shadow={false}
                  onClick={() => {
                    setDataSet((current) =>
                      current.map((item, idx) => {
                        return { ...item, checked: false };
                      })
                    );
                  }}
                >
                  Clear Filters
                </Button>
              </Box>
            </Box>
          </Box>
        )}
      </Flex>
    </Box>
  );
};

export default SearchDropDown;
